<script>
import ObjectsControl from 'controls/objects-control/index.vue'
import LabelListItem from 'list-items/label-list-item.vue'
import Labels from 'components/labels'
import CreateLabelDialog from 'dialogs/create-label-dialog.vue'

export default {
  name: 'LabelsControl',
  components: {
    ObjectsControl,
    Labels,
    LabelListItem,
    CreateLabelDialog
  },
  model: ObjectsControl.model
}
</script>
<template>
  <objects-control
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <template #list="{items}">
      <labels
        :value="items"
        class="px-4 py-2"
        tooltip
      />
    </template>

    <template #list-item="{item, cssClass, itemClass, indent, itemLink, skeleton, selectable, selected, toggleElement}">
      <label-list-item
        :key="item.id"
        :value="item"
        :class="cssClass"
        :item-class="itemClass"
        :indent="indent"
        :skeleton="skeleton"
        :item-link="itemLink"
        :selectable="selectable"
        :selected="selected"
        :show-description="false"
        :tooltip="true"
        v-on="toggleElement ? { click: () => toggleElement(item)}: {}"
      />
    </template>

    <template #create-action="{onCreated}">
      <create-label-dialog
        @created="onCreated"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mt-n2"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </create-label-dialog>
    </template>
  </objects-control>
</template>
