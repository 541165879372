<script>
import findIndex from 'lodash/findIndex'
import has from 'lodash/has'

export default {
  name: 'FilterTabs',
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    counts: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    selectedIndex () {
      return findIndex(this.items, ['value', this.value])
    }
  },
  methods: {
    valueOfIndex (index) {
      return this.items[index].value
    },
    count (value) {
      return has(this.counts, value) ? String(this.counts[value]) : ''
    }
  }
}
</script>
<template>
  <v-tabs
    :value="selectedIndex"
    show-arrows
    @change="$emit('input', valueOfIndex($event))"
  >
    <v-tab
      v-for="item in items"
      :key="item.value"
    >
      <v-icon
        left
        :if="item.icon"
      >
        {{ item.icon }}
      </v-icon>
      {{ item.text }}
      <v-badge
        class="ml-1"
        color="grey lighten-1"
        :content="count(item.value)"
        dense
        inline
      />
    </v-tab>
  </v-tabs>
</template>
