import Page from './page'
import DataFieldFilters, { DATA_FIELD_FILTERS_TYPE } from 'mixins/models/data-field-filters'
import forEach from 'lodash/forEach'
import flattenDeep from 'lodash/flattenDeep'
import has from 'lodash/has'
import parseInt from 'lodash/parseInt'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import union from 'lodash/union'
import cloneDeep from 'lodash/cloneDeep'

export const ListPageContentable = {
  props: {
    filters: {
      type: Object,
      required: true
    },
    pages: {
      type: Object,
      required: true
    }
  }
}

export default {
  name: 'ListPage',
  mixins: [Page, DataFieldFilters],
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return this.pageInitData()
  },
  computed: {
    pageComponentProps () {
      return { filters: this.filters, pages: this.pages }
    }
  },
  methods: {
    pageInitData () {
      return {
        filters: this.createFilters({
          tabs: this.filterTabs(),
          fields: this.filterFields(),
          advancedFilters: this.advancedFilterFilters()
        }),
        pages: {
          current: 1,
          total: this.totalPages()
        }
      }
    },
    onPropUpdated (prop, value, info) {
      switch (prop) {
        case 'filters':
          if (!isNil(info)) {
            this.filters.values[info] = value

            this.filters.flattenedCleanedAdvancedFilters.forEach(filter => {
              if (filter.type === DATA_FIELD_FILTERS_TYPE) this.filters.values[filter.name] = this.dataFieldFiltersCleanupValues(this.filters.values[filter.name])
            })
          } else {
            this.filters.values = value

            this.filters.flattenedCleanedAdvancedFilters.forEach(filter => {
              if (filter.type === DATA_FIELD_FILTERS_TYPE) this.filters.values[filter.name] = this.dataFieldFiltersCleanupValues(this.filters.values[filter.name])
            })

            this.setActiveFilters()
          }
          this.pages.current = 1
          break
        case 'pages':
          this.pages = value
          break
        default:
      }

      this.$router.replace({ name: this.$route.name, query: cloneDeep(this.createQuery()) })
    },
    onBeforePropsChanged () {
      this.updateFilterValuesFromQuery()
      this.updateCurrentPageFromQuery()
    },
    onValueChanged () {
      if ((this.pages.total() < this.pages.current) && (this.pages.total() > 0)) {
        this.pages.current = (this.pages.total() < 1) ? 1 : this.pages.total()
        this.onPropUpdated('pages', this.pages, null)
      }
    },
    filterTabs () {
      return undefined
    },
    filterFields () {
      return undefined
    },
    advancedFilterFilters () {
      return undefined
    },
    totalPages () {
      return this.valueAttributeOrDefault('total_pages', 1)
    },
    valueAttributeOrDefault (attribute, defaultValue = []) {
      return () => { return this.value ? this.value[attribute] : defaultValue }
    },
    hasValueAttribute (attribute) {
      return () => { return has(this.value, attribute) }
    },
    addNoneUser (itemFunction) {
      return this.prependItem(itemFunction, { id: null, fullname: '- keiner -' })
    },
    prependItem (itemFunction, item) {
      return () => { return [item].concat(itemFunction()) }
    },
    createFilters ({ tabs = undefined, fields = undefined, advancedFilters = undefined }) {
      const result = {}
      const values = {}

      if (tabs) {
        result.tabs = tabs
        values[tabs.name] = cloneDeep(tabs.default)
      }

      if (fields) {
        result.fields = fields
        forEach(flattenDeep(fields), (field) => {
          values[field.name] = cloneDeep(field.default)
        })
      }

      if (advancedFilters) {
        result.advancedFilters = advancedFilters
        forEach(flattenDeep(advancedFilters), (advancedFilter) => {
          values[advancedFilter.name] = cloneDeep(advancedFilter.default)
        })
      }

      result.values = values

      const flattenedFilters = flattenDeep(result.fields || [])
      result.flattenedCleanedAdvancedFilters = flattenDeep(result.advancedFilters || []).filter(filter => {
        return (flattenedFilters.find(nf => nf.name === filter.name) === undefined)
      })

      result.active = []
      try {
        result.active = JSON.parse(window.sessionStorage.getItem(this.activeFiltersKey())) || []
      } catch (e) {}

      return result
    },
    updateFilterValuesFromQuery () {
      if (this.filters.tabs) {
        this.updateFilterFieldValueFromQuery(this.filters.tabs, this.filters.values, this.query)
      }

      if (this.filters.fields || this.filters.advancedFilters) {
        const fields = flattenDeep(this.filters.fields || []).concat(flattenDeep(this.filters.advancedFilters || []))
        forEach(fields, (field) => {
          this.updateFilterFieldValueFromQuery(field, this.filters.values, this.query)
        })
      }

      this.setActiveFilters(this.filters.active)
    },
    updateFilterFieldValueFromQuery (field, currentValues, queryValues) {
      if (field.type === DATA_FIELD_FILTERS_TYPE && has(queryValues.__hidden__, field.name)) {
        currentValues[field.name] = queryValues.__hidden__[field.name]
      } else if (has(queryValues, field.name)) {
        currentValues[field.name] = queryValues[field.name]
        if (has(field, 'cast')) currentValues[field.name] = field.cast(currentValues[field.name])
      } else {
        currentValues[field.name] = cloneDeep(field.default)
      }
    },
    updateCurrentPageFromQuery () {
      if (has(this.query, 'page')) {
        this.pages.current = parseInt(this.query.page)
      } else {
        this.pages.current = 1
      }
    },
    createQuery () {
      const result = { __ts__: Date.now(), __hidden__: {} } // fix to update vue router query with arrays (see router)

      if (this.filters.tabs) {
        this.createQueryField(this.filters.tabs, this.filters.values, result)
      }

      if (this.filters.fields || this.filters.advancedFilters) {
        const fields = flattenDeep(this.filters.fields || []).concat(flattenDeep(this.filters.advancedFilters || []))
        forEach(fields, (field) => {
          this.createQueryField(field, this.filters.values, result)
        })
      }

      if (this.pages && this.pages.current !== 1) {
        result.page = this.pages.current
      }

      return result
    },
    createQueryField (field, currentValues, query) {
      let value = cloneDeep(currentValues[field.name])
      if (field.type === DATA_FIELD_FILTERS_TYPE) {
        query.__hidden__[field.name] = currentValues[field.name]
        value = this.dataFieldFiltersRemoveWithEmptyValues(value, field.items())
      }
      if (!isEqual(value, field.default)) {
        query[field.name] = currentValues[field.name]
      }
    },
    activeFiltersKey () {
      return `active-filters-${this.$route.name}`
    },
    setActiveFilters (activeFilters = []) {
      this.filters.flattenedCleanedAdvancedFilters.filter(filter => !isEqual(this.filters.values[filter.name], filter.default)).forEach(filter => {
        const newActiveFilter = { name: filter.name }
        if (filter.type === DATA_FIELD_FILTERS_TYPE) newActiveFilter.activeFieldIds = this.filters.values[filter.name].map(f => f.id)

        const oldActiveFilter = activeFilters.find(af => af.name === filter.name)

        if (oldActiveFilter === undefined) {
          activeFilters = activeFilters.concat(newActiveFilter)
        } else if (oldActiveFilter.type === DATA_FIELD_FILTERS_TYPE) {
          oldActiveFilter.activeFieldIds = union(oldActiveFilter.activeFieldIds, newActiveFilter.activeFieldIds)
        }
      })

      this.filters.active = activeFilters
      window.sessionStorage.setItem(this.activeFiltersKey(), JSON.stringify(this.filters.active))
    },
    cleanedFilterValues () {
      const clonedFilters = cloneDeep(this.filters)

      clonedFilters.flattenedCleanedAdvancedFilters.forEach(filter => {
        if (filter.type === DATA_FIELD_FILTERS_TYPE) clonedFilters.values[filter.name] = this.dataFieldFiltersRemoveWithEmptyValues(clonedFilters.values[filter.name], filter.items())
      })

      return clonedFilters.values
    }
  }
}
