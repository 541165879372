<script>
import Cacheable from 'mixins/cacheable'
import DataFieldFilters, { DATA_FIELD_FILTERS_TYPE } from 'mixins/models/data-field-filters'
import CustomDialog from 'dialogs/custom-dialog.vue'
import FilterItem from 'components/data-list/filter-item.vue'
import flattenDeep from 'lodash/flattenDeep'
import isArray from 'lodash/isArray'

export default {
  name: 'AdvancedFilterDialog',
  components: {
    CustomDialog,
    FilterItem
  },
  mixins: [Cacheable, DataFieldFilters],
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogOpen: false
    }
  },
  computed: {
    filtersWithCols () {
      return this.filters.map(filterOrRow => {
        return isArray(filterOrRow) ? filterOrRow : [filterOrRow]
      })
    }
  },
  methods: {
    onUpdateFilterItemValue ({ value, field }) {
      this.cacheValue[field] = value
    },
    onClickOk () {
      this.dialogOpen = false

      this.$emit('update:filters', this.cacheValue)
    },
    onClickReset () {
      flattenDeep(this.filters).forEach(filter => {
        if (this.isDataFieldFilter(filter)) {
          this.cacheValue[filter.name] = []
        } else {
          this.cacheValue[filter.name] = filter.default
        }
      })
    },
    isDataFieldFilter (filter) {
      return filter.type === DATA_FIELD_FILTERS_TYPE
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('advancedFilter.dialog.title')"
    :ok-btn-text="$t('advancedFilter.dialog.buttons.ok')"
    :cancel-btn-text="$t('advancedFilter.dialog.buttons.reset')"
    :close-on-button-click="false"
    fullheight
    @click-ok="onClickOk"
    @click-cancel="onClickReset"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>

    <v-container>
      <v-row
        v-for="(cols, rowIndex) in filtersWithCols"
        :key="rowIndex"
      >
        <v-col
          v-for="(filter, colIndex) in cols"
          :key="colIndex"
          class="pa-2"
          :cols="filter.cols"
        >
          <v-divider
            v-if="isDataFieldFilter(filter) && rowIndex > 0"
            class="mt-2 mb-4"
          />

          <filter-item
            :filter="filter"
            :value="cacheValue[filter.name]"
            @update:filter="onUpdateFilterItemValue"
          />
        </v-col>
      </v-row>
    </v-container>
  </custom-dialog>
</template>
