import TaskListPage from './list'
import ListPage from '../list-page'
import { DATA_FIELD_FILTERS_TYPE } from 'mixins/models/data-field-filters.js'
import map from 'lodash/map'

export default {
  name: 'TaskListByDefinitionPage',
  mixins: [TaskListPage],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    initPageRequestParams () {
      const params = TaskListPage.methods.initPageRequestParams.call(this)
      delete params.workflow_definition_ids
      params.workflow_definition_id = this.id
      return params
    },
    advancedFilterFilters () {
      const fields = TaskListPage.methods.advancedFilterFilters.call(this)
      return fields.filter((element) => element.name !== 'workflow_definition_ids').concat(
        [
          {
            name: 'task_definition_ids',
            type: 'multi-select',
            label: 'Aufgabenvorlage',
            items: this.valueAttributeOrDefault('task_definitions'),
            default: [],
            cast: (value) => map([value].flat(), (value) => { return value === null ? null : parseInt(value) }),
            active: this.hasValueAttribute('task_definitions')
          },
          {
            name: 'fields',
            type: DATA_FIELD_FILTERS_TYPE,
            items: this.valueAttributeOrDefault('fieldDefinitions'),
            comparators: this.valueAttributeOrDefault('comparators', {}),
            default: [],
            cast: (values) => [values].flat().map(val => Object.assign({}, val))
          }
        ]
      )
    },
    activeFiltersKey () {
      return `${ListPage.methods.activeFiltersKey.call(this)}-${this.id}`
    }
  },
  watch: {
    id: {
      handler (value, oldValue) {
        const initData = this.pageInitData()
        this.filters = initData.filters
        this.pages = initData.pages
      }
    }
  }
}
