<script>
import Vue from 'vue'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import Avatar from 'components/avatar.vue'
import ActivityHubCommentEditor from './activity-hub-comment-editor'
import ActivityHubItem from './activity-hub-item.vue'
import filter from 'lodash/filter'
import sumBy from 'lodash/sumBy'

export default {
  name: 'ActivityHub',
  components: {
    Avatar,
    ActivityHubCommentEditor,
    ActivityHubItem
  },
  props: {
    gid: {
      type: String,
      required: true
    },
    groups: {
      type: Array,
      default: () => []
    },
    editorHint: {
      type: String,
      default: undefined
    },
    hideComments: {
      type: Boolean,
      default: false
    },
    timeTracking: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedTabIndex: undefined,
      lastSelectedTabIndex: 0,
      currentComment: null,
      currentTimeTracking: null,
      events: [],
      loadEventsRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.events = data
            this.$emit('comments-count-updated', this.notDeletedCommentEvents.length)
            this.$emit('loaded')
          }
        }
      }),
      loadEventsInBackgroundRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.events = data
            this.$emit('comments-count-updated', this.notDeletedCommentEvents.length)
            if (this.timeTracking) this.$emit('time-tracking-updated', this.timeTrackingSpentInMinutesFromEvents)
          }
        }
      }),
      createCommentRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.currentComment = null
            this.currentTimeTracking = null
          }
        }
      })
    }
  },
  channels: {
    computed: [
      {
        channelName () {
          return this.channelName
        },
        received (data) {
          if (data.action === 'events_updated') {
            this.loadEvents(this.gid, true)
          }
        }
      }
    ]
  },
  computed: {
    tabs () {
      return [...(!this.hideComments ? ['comments'] : []), 'events', ...(!this.hideComments ? ['all'] : [])]
    },
    hasTabComments () {
      return this.tabs.includes('comments')
    },
    hasTabEvents () {
      return this.tabs.includes('events')
    },
    hasTabAll () {
      return this.tabs.includes('all')
    },
    tabCommentsSelected () {
      return this.hasTabComments && this.selectedTabIndex === this.tabs.indexOf('comments')
    },
    tabEventsSelected () {
      return this.hasTabEvents && this.selectedTabIndex === this.tabs.indexOf('events')
    },
    tabAllSelected () {
      return this.hasTabAll && this.selectedTabIndex === this.tabs.indexOf('all')
    },
    allEvents () {
      return this.events ? this.events : []
    },
    commentEvents () {
      return filter(this.allEvents, (e) => e.action === 'commented' || e.action === 'comment_deleted')
    },
    notDeletedCommentEvents () {
      return filter(this.allEvents, (e) => e.action === 'commented')
    },
    otherEvents () {
      return filter(this.allEvents, (e) => e.action !== 'commented' && e.action !== 'comment_deleted')
    },
    selectedEvents () {
      if (this.tabCommentsSelected) return this.commentEvents
      if (this.tabEventsSelected) return this.otherEvents
      if (this.tabAllSelected) return this.allEvents
      return []
    },
    timeTrackingSpentInMinutesFromEvents () {
      return sumBy(filter(this.allEvents, (e) => e.action === 'commented' && e.payload?.time_tracking !== undefined), 'payload.time_tracking.timeSpentInMinutes')
    },
    channelName () {
      return `monitor_channel_${this.gid}`
    }
  },
  watch: {
    gid: {
      handler (newVal, oldVal) {
        this.loadEvents(this.gid, false)
      },
      immediate: true
    },
    'loadEventsRequestable.requestableLoading': {
      handler () {
        if (this.loadEventsRequestable.requestableLoading) {
          this.lastSelectedTabIndex = this.selectedTabIndex
          this.selectedTabIndex = undefined
        } else {
          this.selectedTabIndex = this.lastSelectedTabIndex
        }
      }
    }
  },
  methods: {
    loadEvents (gid, inBackground) {
      const requestable = inBackground ? this.loadEventsInBackgroundRequestable : this.loadEventsRequestable
      requestable.request(
        { method: Request.GET, url: this.$apiEndpoints.events.index() },
        { object_gid: gid }, null, true, true)
    },
    createComment () {
      this.createCommentRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.events.createComment() },
        { object_gid: this.gid }, {
          message: this.currentComment,
          ...(this.timeTracking
            ? {
                time_spent_at: this.currentTimeTracking?.spentAt,
                time_spent_in_minutes: this.currentTimeTracking?.timeSpentInMinutes
              }
            : {})
        }
      )
    },
    scrollToAndShowComments () {
      if (this.hasTabComments) {
        this.selectTab('comments')
        this.$vuetify.goTo(this, {})
      }
    },
    selectTab (type) {
      const index = this.tabs.indexOf(type)
      if (index > -1) this.selectedTabIndex = index
    }
  }
}
</script>
<template>
  <div class="activity-hub">
    <v-tabs
      v-model="selectedTabIndex"
      :hide-slider="loadEventsRequestable.requestableLoading"
    >
      <v-tab
        v-if="hasTabComments"
        :disabled="loadEventsRequestable.requestableLoading || createCommentRequestable.requestableLoading"
      >
        DISKUSSION
        <v-badge
          v-if="!loadEventsRequestable.requestableLoading"
          class="ml-1"
          :color="tabCommentsSelected ? 'primary' : 'grey lighten-1'"
          :content="notDeletedCommentEvents.length.toString()"
          dense
          inline
        />
      </v-tab>

      <v-tab
        v-if="hasTabEvents"
        :disabled="loadEventsRequestable.requestableLoading || createCommentRequestable.requestableLoading"
      >
        HISTORIE
        <v-badge
          v-if="!loadEventsRequestable.requestableLoading"
          class="ml-1"
          :color="tabEventsSelected ? 'primary' : 'grey lighten-1'"
          :content="otherEvents.length.toString()"
          dense
          inline
        />
      </v-tab>

      <v-tab
        v-if="hasTabAll"
        :disabled="loadEventsRequestable.requestableLoading || createCommentRequestable.requestableLoading"
      >
        ALLE
        <v-badge
          v-if="!loadEventsRequestable.requestableLoading"
          class="ml-1"
          :color="tabAllSelected ? 'primary' : 'grey lighten-1'"
          :content="events.length.toString()"
          dense
          inline
        />
      </v-tab>
    </v-tabs>
    <v-progress-linear
      v-if="loadEventsRequestable.requestableLoading"
      indeterminate
      height="2"
    />
    <v-divider class="mb-6" />

    <div
      v-if="tabCommentsSelected"
      class="d-flex pa-3"
    >
      <avatar
        :text="$config.current_user.avatar.label"
        :image="$config.current_user.avatar.url"
        active
        class="mr-4 sticky-page"
      />

      <div class="flex-grow-1">
        <activity-hub-comment-editor
          v-model="currentComment"
          placeholder="Schreib einen Kommentar"
          :loading="createCommentRequestable.requestableLoading"
          :disabled="createCommentRequestable.requestableLoading || loadEventsRequestable.requestableLoading"
          :error-messages="createCommentRequestable.errorMessage"
          :groups="groups"
          :hint="editorHint"
          :time-tracking="timeTracking"
          :time-tracking-value.sync="currentTimeTracking"
          :gid="gid"
          content-changed
          class="mb-3"
        />

        <v-btn
          color="primary"
          depressed
          :disabled="createCommentRequestable.requestableLoading || currentComment === null"
          @click="createComment"
        >
          Kommentieren
        </v-btn>
      </div>
    </div>

    <v-timeline
      v-if="selectedEvents.length"
      class="mt-4 ml-n5 pr-4"
      dense
    >
      <activity-hub-item
        v-for="(event, index) in selectedEvents"
        :key="index"
        :event="event"
        :groups="groups"
        :editor-hint="editorHint"
        :time-tracking="timeTracking"
        show-actions
      />
    </v-timeline>
  </div>
</template>
