<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Request from 'api/request'
import Label from 'mixins/models/label'
import TextControl from 'controls/text-control.vue'
import TextareaControl from 'controls/textarea-control.vue'
import ColorControl from 'controls/color-control.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'LabelEditContent',
  components: {
    ColorControl,
    TextareaControl,
    MonitorSubscription,
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    CustomDialog,
    TextControl
  },
  mixins: [PageContentable, Label],
  inject: {
    helper: {
      default: {
        Request
      }
    }
  },
  computed: {
    label () {
      return this.value
    },
    labelGlobalId () {
      return this.labelGlobalIdFor({ id: this.pageProps.id })
    }
  },
  methods: {
    ...{ routeFor },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onDeleted () {
      this.$router.replace({ name: 'labels' })
    }
  }
}
</script>
<template>
  <div v-if="value">
    <monitor-subscription
      :key="`monitor-channel-${labelGlobalId}`"
      :gid="labelGlobalId"
    />

    <breadcrumbs :items="labelBreadcrumbs" />

    <page-detail-header
      :state-text="labelStateText"
      :state-updated-at="labelStateUpdatedAtDate"
      :state-color="labelStateColor.background"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          :disabled="loading"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <custom-dialog
              :title="$t('label.deleteDialog.title')"
              :text="$t('label.deleteDialog.text')"
              :ok-btn-text="$t('label.deleteDialog.buttons.ok')"
              ok-btn-color="error"
              @click-ok="actionRequest($apiEndpoints.labels.destroy(value.id), onDeleted, helper.Request.DELETE)"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>{{ $t('label.actions.delete') }}</v-list-item-title>
                </v-list-item>
              </template>
            </custom-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <div class="mb-4">
      <h1 class="text-h4 font-weight-light">
        {{ value.title }}
      </h1>
    </div>

    <text-control
      v-model="value.title"
      :label="$t('label.fields.title')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.labels.update(value.id), mapping: 'title'}"
      :hint="$t('general.field.required')"
      persistent-hint
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <textarea-control
      v-model="value.description"
      :label="$t('label.fields.description')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.labels.update(value.id), mapping: 'description'}"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <color-control
      v-model="value.color"
      :label="$t('label.fields.color')"
      :request-parameter="{method: 'patch', url: $apiEndpoints.labels.update(value.id), mapping: 'color'}"
      :colors="labelAvailableColors"
      @request-success-data="onUpdated"
    />
  </div>
</template>
