<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import TextControl from 'controls/text-control.vue'
import TextareaControl from 'controls/textarea-control.vue'
import ColorControl from 'controls/color-control.vue'
import Label from 'mixins/models/label'

export default {
  name: 'CreateLabelDialog',
  components: {
    ColorControl,
    TextareaControl,
    CustomDialog,
    TextControl
  },
  mixins: [Label],
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.$emit('created', data)
              this.successMessage = this.$t('label.createDialog.successMessage', { title: this.label.title })
              this.initLabel()
            } else {
              this.dialogOpen = false
              this.$emit('created', data)
              this.$emit('success', data)
            }
          }
        }
      }),
      label: null
    }
  },
  computed: {
    isValid () {
      return this.label?.title?.trim().length
    }
  },
  methods: {
    initLabel () {
      this.label = {
        title: undefined,
        description: undefined,
        color: undefined
      }
    },
    onDialogOpened () {
      this.initLabel()
      this.createRequestable.resetRequestable()
      this.$emit('open')
    },
    onDialogClosed () {
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      this.createRequestable.request({ method: Request.POST, url: this.$apiEndpoints.labels.create() }, null, this.label)
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('label.createDialog.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="$t('general.buttons.save')"
    :ok-btn-disabled="!isValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    :success-message="successMessage"
    content-class="create-label-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="label">
      <text-control
        v-model="label.title"
        :label="$t('label.fields.title')"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('title')"
        class="mb-5"
      />
      <textarea-control
        v-model="label.description"
        :label="$t('label.fields.description')"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('description')"
        class="mb-5"
      />
      <color-control
        v-model="label.color"
        :label="$t('label.fields.color')"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('color')"
        :colors="labelAvailableColors"
      />
    </template>
  </custom-dialog>
</template>
