import axios from 'axios'
import router from 'router'
import appConfig from 'helpers/config'
import ApiError from './error/api-error'
import RequestError from './error/request-error'
import ResponseError from './error/response-error'
import ValidationError from './error/validation-error'
import merge from 'lodash/merge'
import qs from 'qs'
import * as Sentry from '@sentry/vue'

const csrfToken = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' }
const axiosInstance = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': csrfToken.content,
      Accept: 'application/json'
    }
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { encodeValuesOnly: true, arrayFormat: 'brackets' })
  }
})

if (appConfig.share_token) {
  axiosInstance.interceptors.request.use((config) => {
    config.params = config.params || {} // use config.params if it has been set
    config.params.share_token = appConfig.share_token
    return config
  })
}

export default class Request {
  constructor () {
    this.loading = false
    this.loadingDelayed = false
    this.delayTimerId = null
    this.error = null
    this.cancelTokenSource = null
  }

  static get GET () {
    return 'get'
  }

  static get POST () {
    return 'post'
  }

  static get PATCH () {
    return 'patch'
  }

  static get PUT () {
    return 'put'
  }

  static get DELETE () {
    return 'delete'
  }

  static get METHODS () {
    return [Request.GET, Request.POST, Request.PATCH, Request.PUT, Request.DELETE]
  }

  static setAxiosCSRFToken (token) {
    axiosInstance.defaults.headers.common['X-CSRF-Token'] = token
  }

  static getUri (url, params) {
    return axiosInstance.getUri({ url, params })
  }

  cancel () {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel()
    }
  }

  reset () {
    this.error = null
  }

  request (method, url, params = null, payload = null, useCancelToken) {
    return new Promise((resolve, reject) => {
      if (useCancelToken) {
        this.cancel()
        this.cancelTokenSource = axios.CancelToken.source()
      }

      this.loading = true
      clearTimeout(this.delayTimerId)
      this.delayTimerId = setTimeout(() => { this.loadingDelayed = true }, 400)

      const config = merge({
        url,
        method
      }, this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : {},
      params ? { params } : {},
      payload ? { data: payload } : {}
      )

      axiosInstance.request(config)
        .then(response => {
          this.error = null
          resolve(response.data)
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            if (error.response) {
              if (error.response.status === 422) {
                this.error = new ValidationError(error)
              } else if (error.response.status === 401) {
                window.location.href = router.resolve({ name: 'sign-in-page' }).href
                return
              } else {
                this.error = new ResponseError(error)
                Sentry.captureException(error)
              }
            } else if (error.request) {
              this.error = new RequestError(error)
              Sentry.captureException(error)
            } else {
              this.error = new ApiError(error)
              Sentry.captureException(error)
            }
            reject(this.error)
          } else {
            this.error = null
          }
        })
        .then(() => {
          this.loading = false
          clearTimeout(this.delayTimerId)
          this.loadingDelayed = false
        })
    })
  }

  get (url, params = null, useCancelToken = false) {
    return this.request(Request.GET, url, params, null, useCancelToken)
  }
}
