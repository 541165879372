import ListPage from '../list-page'
import ListContent from './list-content.vue'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'

export default {
  name: 'DossierListPage',
  mixins: [ListPage],
  computed: {
    pageTitleParts () {
      return ['Dossiers']
    },
    pageContentComponents () {
      return ListContent
    }
  },
  methods: {
    initPageRequestUrl () {
      return this.$apiEndpoints.dossiers.index()
    },
    initPageRequestParams () {
      return {
        page: this.pages.current,
        order: this.filters.values.order,
        query: this.filters.values.query,
        definition_ids: this.filters.values.definition_ids,
        fields: this.cleanedFilterValues().fields
      }
    },
    filterFields () {
      return [
        [
          {
            name: 'query',
            type: 'text',
            label: 'Titel / Subtitel',
            default: ''
          },
          {
            name: 'order',
            type: 'single-select',
            label: 'Sortierung',
            items: [
              { text: 'Bearbeitungsdatum – zuletzt bearbeitet', value: 'updated_at_desc' },
              { text: 'Erstellungsdatum – neueste zuerst', value: 'created_at_desc' },
              { text: 'Erstellungsdatum – älteste zuerst', value: 'created_at_asc' },
              { text: 'Titel – aufsteigend', value: 'title_asc' },
              { text: 'Titel – absteigend', value: 'title_desc' }
            ],
            default: 'updated_at_desc',
            cols: 4
          }],
        [
          {
            name: 'definition_ids',
            type: 'multi-select',
            label: 'Dossiervorlage',
            items: this.valueAttributeOrDefault('dossier_definitions'),
            default: [],
            cast: (value) => map([value].flat(), parseInt)
          }
        ]
      ]
    }
  }
}
