<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import TaskListItem from 'list-items/task-list-item.vue'
import Avatar from 'components/avatar.vue'
import ProcessTitle from 'components/process-title.vue'
import AddTaskDialog from 'dialogs/add-task-dialog.vue'
import AddEditBlockDialog from 'dialogs/add-edit-block-dialog.vue'
import DeleteBlockDialog from 'dialogs/delete-block-dialog.vue'
import Labels from 'components/labels.js'
import Workflow from 'mixins/models/workflow'
import ProcessDefinition from 'mixins/models/process-definition'
import find from 'lodash/find'
import parseInt from 'lodash/parseInt'
import draggable from 'vuedraggable'
import has from 'lodash/has'
import last from 'lodash/last'
import { routeFor } from 'helpers/route'

export default {
  name: 'TaskEditSidebarLeft',
  components: {
    Labels,
    TaskListItem,
    Avatar,
    ProcessTitle,
    AddTaskDialog,
    AddEditBlockDialog,
    DeleteBlockDialog,
    draggable
  },
  mixins: [PageContentable, Workflow, ProcessDefinition],
  props: {
    designMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      currentDragItem: null,
      resetCurrentDragItemTimeoutId: undefined,
      designRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: this.onUpdated
        }
      }),
      addButtonOpen: false,
      updated: false
    }
  },
  computed: {
    workflow () {
      return this.value ? this.value.workflow : null
    },
    task () {
      return this.value ? this.value.task : null
    },
    processDefinition () {
      return this.workflow?.definition
    }
  },
  watch: {
    value: {
      handler (value, oldValue) {
        if (value?.workflow && value?.task && (value.workflow.id !== oldValue?.workflow?.id || this.updated)) {
          this.$nextTick(() => document.querySelector(`.sidebar-left .task--${this.task.id}`).scrollIntoView(false))
        }
        this.updated = false
      },
      deep: true,
      immediate: false
    }
  },
  updated () {
    this.updated = true
  },
  methods: {
    ...{ routeFor },
    onUpdated (workflow) {
      this.$emit('input', {
        workflow,
        task: this.task
      })
    },
    onNewTask (workflow) {
      this.onUpdated(workflow)
      this.$router.push(routeFor('task', last(workflow.structure).id))
      this.$emit('component:event', 'load:content-items')
    },
    blockContainsCurrentTask (block) {
      return this.task && !!find(block.tasks, { id: parseInt(this.task.id) })
    },
    putInBlock (to, from, dragEl, event) {
      return dragEl.__vue__ && !dragEl.__vue__?.$attrs['drag-item']
    },
    onChoose (event) {
      if (event.item.__vue__.$attrs['drag-item']) {
        clearTimeout(this.resetCurrentDragItemTimeoutId)
        event.item.querySelector('.v-list-group__items').style.display = 'none'
        this.currentDragItem = event.item.__vue__.$attrs['drag-item']()
      }
    },
    onUnchoose (event) {
      clearTimeout(this.resetCurrentDragItemTimeoutId)
      this.resetCurrentDragItemTimeoutId = setTimeout(() => {
        this.currentDragItem = null
      }, 100)
    },
    onPositionChanged (parent, event) {
      if (has(event, 'moved')) {
        this.moveItem(event.moved.element.type, event.moved.element.id, event.moved.newIndex, parent.type, parent.id)
      } else if (has(event, 'added')) {
        this.moveItem(event.added.element.type, event.added.element.id, event.added.newIndex, parent.type, parent.id)
      }
    },
    moveItem (type, id, newIndex, parentType, parentId) {
      if (type === 'task') {
        this.designRequestable.request(
          { method: 'patch', url: this.$apiEndpoints.tasks.move(id) },
          { target_type: parentType, target_id: parentId, index: newIndex })
      } else if (type === 'block') {
        this.designRequestable.request(
          { method: 'patch', url: this.$apiEndpoints.blocks.move(id) },
          { target_type: parentType, target_id: parentId, index: newIndex })
      }
    }
  }
}
</script>
<template>
  <v-list
    v-if="value"
    class="sidebar-left"
  >
    <v-list-item
      v-if="!workflowIsSingleTaskFor(workflow)"
      :to="routeFor('workflow', workflow.id)"
      class="d-flex pl-1 mb-2 sticky-border--workflow"
    >
      <v-list-item-content class="mx-2">
        <v-list-item-subtitle
          class="text-uppercase"
          style="letter-spacing: .1em"
        >
          Prozess
        </v-list-item-subtitle>
        <v-list-item-title>
          <process-title :workflow="workflow" />
        </v-list-item-title>

        <labels
          :value="workflow.labels"
          wrapper="v-list-item-subtitle"
          small
          class="mt-1"
        />
      </v-list-item-content>
      <avatar
        v-if="workflow.assignee"
        :text="workflow.assignee.avatar.label"
        :image="workflow.assignee.avatar.url"
        :tooltip-title="workflow.assignee.fullname"
        :tooltip-subtitle="workflow.assignee.email"
        list
      />
    </v-list-item>

    <div class="pr-4 d-flex align-center">
      <v-subheader
        class="flex-grow-1 text-uppercase pl-3"
        style="letter-spacing: .1em"
      >
        Aufgaben
      </v-subheader>

      <v-menu
        v-model="addButtonOpen"
        left
        bottom
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <add-task-dialog
            :workflow="workflow"
            @open="addButtonOpen = false"
            @request-success-data="onNewTask"
          >
            <template #activator="{ on }">
              <v-list-item
                :disabled="!workflowHasAvailableActionFor(workflow, 'design')"
                v-on="on"
              >
                {{ $t('workflow.actions.addTask') }}
              </v-list-item>
            </template>
          </add-task-dialog>
          <add-edit-block-dialog
            :request-parameter="{method: 'post', url: $apiEndpoints.workflows.addBlock(workflow.id), mapping: 'block'}"
            :workflow="workflow"
            add-mode
            @open="addButtonOpen = false"
            @request-success-data="onUpdated"
          >
            <template #activator="{ on }">
              <v-list-item
                :disabled="!workflowHasAvailableActionFor(workflow, 'design')"
                v-on="on"
              >
                {{ $t('workflow.actions.addBlock') }}
              </v-list-item>
            </template>
          </add-edit-block-dialog>
        </v-list>
      </v-menu>
    </div>

    <draggable
      :list="workflow.structure"
      :group="{ name: 'workflow-structure-group' }"
      handle=".handle"
      :force-fallback="$browserDetect.isChrome"
      :animation="200"
      direction="vertical"
      :empty-insert-threshold="10"
      fallback-on-body
      swap-threshold="0.2"
      invert-swap
      @change="onPositionChanged({ type: 'workflow', id: workflow.id }, $event)"
      @choose="onChoose"
      @unchoose="onUnchoose"
    >
      <template
        v-for="item in workflow.structure"
      >
        <task-list-item
          v-if="item.type === 'task'"
          :key="`${item.type}-${item.id}`"
          :value="{...item, workflow: workflow}"
          :to="routeFor('task', item.id)"
          :dense="false"
          :ripple="false"
          class="mb-3 mr-4 sticky-border--task"
          :class="`task--${item.id}`"
          indent
          sidebar
          :design-mode="designMode"
          :disabled="designRequestable.requestableLoading"
        />
        <v-list
          v-else-if="item.type === 'block'"
          :key="`${item.type}-${item.id}`"
          class="mb-3 mr-4 py-0"
          :drag-item="() => item"
        >
          <v-list-group
            :value="!(currentDragItem && currentDragItem.id === item.id) && (designMode || !item.condition || item.condition.valid || item.important || blockContainsCurrentTask(item))"
            :ripple="false"
            :disabled="designRequestable.requestableLoading"
            color="secondary-text"
            class="sticky-border--block"
          >
            <template #activator>
              <v-icon
                v-if="designMode"
                :disabled="designRequestable.requestableLoading"
                color="purple lighten-2"
                class="handle ml-n2 mr-2"
              >
                mdi-drag-horizontal
              </v-icon>
              <v-icon
                v-if="item.condition"
                dense
                class="mr-1"
                :class="item.condition.confirmed ? 'text--secondary' : 'text--disabled'"
              >
                {{ !item.condition.set ? 'mdi-shield-outline' : (item.condition.valid ? 'mdi-shield-check-outline' : 'mdi-shield-lock-outline') }}
              </v-icon>
              <v-list-item-title
                class="text--secondary"
                :class="{'text--disabled': item.condition && !item.condition.valid}"
              >
                {{ item.title }}
              </v-list-item-title>

              <add-edit-block-dialog
                v-if="designMode"
                :value="item"
                :request-parameter="{ method: 'patch', url: workflow ? $apiEndpoints.blocks.update(item.id) : '', mapping: 'block' }"
                :workflow="workflow"
                @request-success-data="onUpdated"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    :disabled="designRequestable.requestableLoading"
                    v-on="on"
                  >
                    <v-icon color="purple lighten-2">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
              </add-edit-block-dialog>

              <delete-block-dialog
                v-if="designMode"
                :request-parameter="{ method: 'delete', url: $apiEndpoints.blocks.destroy(item.id) }"
                @request-success-data="onUpdated"
              >
                <template #activator="{ on: onDialog }">
                  <v-tooltip
                    bottom
                    :disabled="item.tasks.length === 0"
                  >
                    <template #activator="{ on: onTooltip }">
                      <div
                        class="d-inline-block"
                        v-on="onTooltip"
                      >
                        <v-btn
                          icon
                          :disabled="designRequestable.requestableLoading || item.tasks.length > 0"
                          v-on="onDialog"
                        >
                          <v-icon color="purple lighten-2">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t('block.tooltips.notDeletable') }}</span>
                  </v-tooltip>
                </template>
              </delete-block-dialog>
            </template>

            <draggable
              :list="item.tasks"
              :group="{ name: 'workflow-structure-group', put: putInBlock }"
              handle=".handle"
              :force-fallback="$browserDetect.isChrome"
              :animation="200"
              direction="vertical"
              :empty-insert-threshold="10"
              fallback-on-body
              swap-threshold="0.2"
              invert-swap
              :class="{'group-drop-zone': designMode && item.tasks.length === 0}"
              @change="onPositionChanged({ type: 'block', id: item.id }, $event)"
              @choose="onChoose"
              @unchoose="onUnchoose"
            >
              <task-list-item
                v-for="blockTask in item.tasks"
                :key="`${blockTask.type}-${blockTask.id}`"
                :value="{...blockTask, workflow: workflow}"
                :to="routeFor('task', blockTask.id)"
                :dense="false"
                :ripple="false"
                class="my-2 mr-2 sticky-border--task"
                :class="`task--${blockTask.id}`"
                indent
                sidebar
                :content-class="item.condition && !item.condition.valid ? 'text--disabled' : ''"
                :design-mode="designMode"
                :disabled="designRequestable.requestableLoading"
              />
            </draggable>
          </v-list-group>
        </v-list>
      </template>
    </draggable>
  </v-list>
</template>

<style>
.v-list.sidebar-left {
  padding-bottom: 98px;
}
.sticky-border--workflow {
  border-color: rgba(0,0,0,.12);
  border-width: 1px;
  border-style: solid;
  border-radius: 0 4px 4px 0;
  border-left: none;
}
.sticky-border--task {
  border-color: rgba(0,0,0,.24);
  border-width: 1px;
  border-style: solid;
  border-radius: 0 4px 4px 0;
  border-left: none;
}
.sticky-border--block {
  border-color: rgba(0,0,0,.24);
  border-width: 1px;
  border-style: dashed;
  border-radius: 0 4px 4px 0;
  border-left: none;
}

.group-drop-zone {
  min-height: 20px;
}

.sticky-border--block .v-list-group__header.v-list-item {
  padding-left: 12px;
}

.sortable-drag.sticky-border--task {
  background-color: white;
  border-left: rgba(0,0,0,.24) 1px solid;
}
.sortable-drag > .sticky-border--block {
  background-color: white;
  border-left: rgba(0,0,0,.24) 1px dashed;
  height: 50px !important;
}

.sortable-drag > .v-list-item--active:before {
  opacity: 0 !important;
}

</style>
