export default {
  actions: {
    create: 'Label erstellen',
    delete: 'Label löschen'
  },
  createDialog: {
    title: 'Label erstellen',
    successMessage: 'Label "{title}" wurde erfolgreich erstellt'
  },
  deleteDialog: {
    title: 'Label löschen?',
    text: 'Löschen Sie das Label, wenn Sie sicher sind, dass dies nicht mehr benötigt wird. Gelöschte Labels sind unwiederbringlich verloren.',
    buttons: {
      ok: 'Label löschen'
    }
  },
  fields: {
    title: 'Titel',
    description: 'Beschreibung',
    color: 'Farbe'
  }
}
