export default {
  actions: {
    complete: 'Zuarbeit abschließen'
  },
  dialogs: {
    complete: {
      title: 'Zuarbeit abschließen',
      text: 'Nach dem Abschließen der Zuarbeit können die eingegebenen Daten nicht mehr geändert werden und sind hier nicht mehr sichtbar. Bitte prüfen Sie vor dem Abschließen ob Sie alle Daten korrekt eingegeben haben.',
      buttons: {
        ok: 'Zuarbeit abschließen'
      }
    }
  },
  introWithRequesterText: 'bittet um die folgende Zuarbeit.',
  introWithoutRequesterText: 'Wir bitten um die folgende Zuarbeit.',
  completedText: 'Vielen Dank! Die Zuarbeit wurde abgeschlossen.',
  completeButtonHint: 'Deine Eingaben werden automatisch zwischengespeichert. Nach dem Abschließen der Zuarbeit können die eingegebenen Daten nicht mehr geändert werden und sind hier nicht mehr sichtbar.'
}
