<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import defaults from 'lodash/defaults'

export default {
  name: 'TextControl',
  components: {
    ResetBtn,
    CopyToClipboardBtn
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    internalOptions () {
      return defaults({}, this.options, {
        type: 'text',
        autocomplete: undefined
      })
    },
    isTypePassword () {
      return this.internalOptions.type === 'password'
    },
    isTypeLikePassword () {
      return this.internalOptions.type === 'like-password'
    },
    isTypeEmail () {
      return this.internalOptions.type === 'email'
    },
    isTypeUrl () {
      return this.internalOptions.type === 'url'
    },
    isTypePhoneNumber () {
      return this.internalOptions.type === 'phone-number'
    },
    type () {
      if (!this.isTypePassword && !this.isTypeLikePassword) return this.internalOptions.type

      return this.showPassword ? 'text' : 'password'
    },
    autocomplete () {
      return this.internalOptions.autocomplete
    },
    hasErrorOrEmpty () {
      return !this.cacheValue || (this.$refs.component !== undefined && this.$refs.component.hasError)
    },
    link () {
      if (this.isTypeUrl) {
        if (!/^https?:\/\//i.test(this.cacheValue)) {
          return 'http://' + this.cacheValue
        } else {
          return this.cacheValue
        }
      } else if (this.isTypeEmail) {
        return `mailto:${this.cacheValue}`
      } else if (this.isTypePhoneNumber) {
        return `tel:${this.cacheValue}`
      }

      return undefined
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    onClearButtonPressed () {
      this.cacheValue = null
      this.controlOnBlur()
      this.controlOnChange()
      this.controlOnUnlock()
    }
  }
}
</script>

<template>
  <v-text-field
    ref="component"
    v-model="cacheValue"
    :label="label"
    :type="type"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :dense="controlDense"
    :hide-details="controlHideDetails"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    :autocomplete="autocomplete"
    :clearable="clearable && !controlDisabled"
    @blur="controlOnBlur(); controlOnChange(); controlOnUnlock();"
    @input="controlOnInput"
    @focus="controlOnFocus(); controlOnLock();"
    @click:clear="onClearButtonPressed"
  >
    <template
      v-if="!hideAppend"
      #append
    >
      <v-btn
        v-if="isTypePassword || isTypeLikePassword"
        icon
        @click="showPassword = !showPassword"
      >
        <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
      </v-btn>
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
      <copy-to-clipboard-btn
        v-if="!isTypePassword && cacheValue"
        :value="{'text/plain': cacheValue}"
        small
      />
    </template>

    <template
      v-if="!hideAppend && (isTypeUrl || isTypeEmail || isTypePhoneNumber)"
      #append-outer
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :disabled="hasErrorOrEmpty"
            icon
            :href="link"
            :target="isTypePhoneNumber ? undefined : '_blank'"
            v-on="on"
          >
            <v-icon v-if="isTypeUrl">
              mdi-open-in-new
            </v-icon>
            <v-icon v-else-if="isTypeEmail">
              mdi-email-edit-outline
            </v-icon>
            <v-icon v-else-if="isTypePhoneNumber">
              mdi-phone
            </v-icon>
          </v-btn>
        </template>
        <span v-if="isTypeUrl">Link öffnen</span>
        <span v-else-if="isTypeEmail">E-Mail verfassen</span>
        <span v-else-if="isTypePhoneNumber">Anrufen</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>
