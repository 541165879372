<script>
import FilterText from './filter-text.vue'
import FilterSingleSelect from './filter-single-select.vue'
import FilterUserMultiSelect from './filter-user-multi-select.vue'
import FilterMultiSelect from './filter-multi-select.vue'
import FilterDataFields from 'components/data-list/filter-data-fields.vue'
import { DATA_FIELD_FILTERS_TYPE } from 'mixins/models/data-field-filters'
import isFunction from 'lodash/isFunction'
import has from 'lodash/has'

export default {
  name: 'DataListFilterItem',
  components: {
    FilterText,
    FilterSingleSelect,
    FilterMultiSelect,
    FilterUserMultiSelect,
    FilterDataFields
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    value: undefined
  },
  computed: {
    DATA_FIELD_FILTERS_TYPE () { return DATA_FIELD_FILTERS_TYPE }
  },
  methods: {
    getValue (value) {
      return isFunction(value) ? value() : value
    },
    updateFilter (value, field) {
      this.$emit('update:filter', { value, field })
    },
    isActiveWithType (filter, type) {
      return filter.type === type && (!has(filter, 'active') || this.getValue(filter.active))
    }
  }
}
</script>
<template>
  <filter-text
    v-if="isActiveWithType(filter, 'text')"
    :value="value"
    :label="filter.label"
    :disabled="getValue(filter.disabled)"
    @input="updateFilter($event, filter.name)"
  />
  <filter-single-select
    v-else-if="isActiveWithType(filter, 'single-select')"
    :value="value"
    :label="filter.label"
    :items="getValue(filter.items)"
    :clearable="filter.clearable"
    :disabled="getValue(filter.disabled)"
    @input="updateFilter($event, filter.name)"
  />
  <filter-multi-select
    v-else-if="isActiveWithType(filter, 'multi-select')"
    :value="value"
    :label="filter.label"
    :items="getValue(filter.items)"
    @input="updateFilter($event, filter.name)"
  />
  <filter-user-multi-select
    v-else-if="isActiveWithType(filter, 'user-multi-select')"
    :value="value"
    :label="filter.label"
    :items="getValue(filter.items)"
    @input="updateFilter($event, filter.name)"
  />
  <filter-data-fields
    v-else-if="isActiveWithType(filter, DATA_FIELD_FILTERS_TYPE)"
    :value="value"
    :items="getValue(filter.items)"
    :comparators="getValue(filter.comparators)"
    @input="updateFilter($event, filter.name)"
  />
</template>
