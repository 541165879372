<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import colors from 'vuetify/lib/util/colors'
import toUpper from 'lodash/toUpper'
import isString from 'lodash/isString'

export default {
  name: 'ColorControl',
  components: {
    ResetBtn,
    CopyToClipboardBtn
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: String,
      default: undefined
    },
    colors: {
      type: Array,
      default: () => Object.keys(colors).filter(color => color !== 'shades').map(color => colors[color].base).concat(['#000000'])
    }
  },
  computed: {
    isEmpty () {
      return !isString(this.cacheValue) || this.cacheValue.trim().length === 0
    },
    hexColors () {
      return this.colors?.map(color => toUpper(color))
    }
  },
  methods: {
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    onChange () {
      this.cacheValue = toUpper(this.cacheValue)
      this.controlOnChange()
    },
    onClearButtonPressed () {
      this.cacheValue = null
    },
    selectColor (color) {
      this.cacheValue = color
      this.controlOnInput()
      this.onChange()
    }
  }
}
</script>
<template>
  <div class="color-control">
    <v-text-field
      v-model="cacheValue"
      :label="label"
      :loading="controlLoading"
      :disabled="controlDisabled"
      :readonly="controlReadonly"
      :dense="controlDense"
      :hide-details="controlHideDetails"
      :class="controlClass"
      :error-count="Number.MAX_VALUE"
      :error-messages="controlErrorMessages"
      :hint="controlHint"
      :persistent-hint="controlPersistentHint"
      :success-messages="controlSuccessMessage"
      :clearable="clearable && !controlDisabled"
      @blur="controlOnBlur(); onChange(); controlOnUnlock();"
      @input="controlOnInput"
      @focus="controlOnFocus(); controlOnLock();"
      @click:clear="onClearButtonPressed"
    >
      <template
        v-if="!hideAppend"
        #append
      >
        <reset-btn
          v-if="cachedValueChanged && controlHasError"
          @click="controlReset"
        />
        <copy-to-clipboard-btn
          v-if="cacheValue"
          :value="{'text/plain': cacheValue}"
          small
        />
      </template>

      <template #prepend>
        <v-sheet
          :color="cacheValue"
          width="30px"
          height="30px"
          outlined
          rounded
        >
          <svg
            v-if="isEmpty"
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <line
              x1="0"
              y1="100"
              x2="100"
              y2="0"
              vector-effect="non-scaling-stroke"
              stroke="red"
            />
          </svg>
        </v-sheet>
      </template>
    </v-text-field>

    <div class="d-flex flex-wrap mt-2 ml-n2 mb-n2">
      <v-sheet
        v-for="color in hexColors"
        :key="color"
        class="ml-2 mb-2"
        :color="color"
        width="32px"
        height="32px"
        rounded
        @click="selectColor(color)"
      />
    </div>
  </div>
</template>
