<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import CalendarSyncIcon from 'components/calendar-sync-icon.vue'
import Controlable from 'mixins/controlable'
import { formatDateTime, iso8601DateObject } from 'helpers/date'
import defaults from 'lodash/defaults'

export default {
  name: 'DatetimeControl',
  components: {
    ResetBtn,
    CopyToClipboardBtn,
    CalendarSyncIcon
  },
  mixins: [Requestable, Controlable],
  inject: {
    helper: {
      default: {
        iso8601DateObject
      }
    }
  },
  props: {
    ...requestablePropFactory().props,
    value: {
      type: String,
      default: undefined
    },
    hideDeleteBtn: {
      type: Boolean,
      default: false
    },
    selectOnClick: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuOpen: false,
      activeTab: 0,
      cacheValueDatetime: null
    }
  },
  computed: {
    internalOptions () {
      return defaults(this.options, {
        add_to_calendar: false
      })
    },
    localizedDateValue () {
      if (this.cacheValue) {
        return formatDateTime(new Date(this.cacheValue))
      } else {
        return ''
      }
    },
    cacheValue () {
      return this.cacheValueDatetime.date && this.cacheValueDatetime.time ? `${this.cacheValueDatetime.date}T${this.cacheValueDatetime.time}` : null
    },
    cachedValueChanged () {
      if (this.value && this.cacheValue) {
        return new Date(this.value).getTime() !== new Date(this.cacheValue).getTime()
      } else {
        return this.value !== this.cacheValue
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.createCacheValue()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onMenuOpen (payload) {
      if (payload && this.$refs.timePicker !== undefined) {
        this.$refs.timePicker.selectingHour = true
        this.activeTab = 0
      }
    },
    onMenuStateChanged (opened) {
      if (opened) {
        if (this.$refs.timePicker !== undefined) {
          this.$refs.timePicker.selectingHour = true
          this.activeTab = 0
        }
        this.controlOnLock()
      } else {
        this.controlOnInput()
        this.controlOnChange()
        this.controlOnUnlock()
      }
    },
    onTimeValueChanged (value) {
      if (this.selectOnClick) this.$refs.menu.save(this.cacheValueDatetime)
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    createCacheValue () {
      this.cacheValueDatetime = iso8601DateObject(this.value)
    },
    onClearButtonPressed () {
      this.cacheValueDatetime = iso8601DateObject(null)
      this.onMenuStateChanged(false)
    }
  }
}
</script>
<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuOpen"
      :close-on-content-click="false"
      :return-value.sync="cacheValueDatetime"
      min-width="290px"
      offset-y
      transition="scale-transition"
      @input="onMenuStateChanged"
    >
      <template #activator="{ on }">
        <v-text-field
          :value="localizedDateValue"
          :label="label"
          :loading="controlLoading"
          :disabled="controlDisabled"
          :dense="controlDense"
          :hide-details="controlHideDetails"
          :class="controlClass"
          :error-messages="controlErrorMessages"
          :error-count="Number.MAX_VALUE"
          :hint="controlHint"
          :persistent-hint="controlPersistentHint"
          :success-messages="controlSuccessMessage"
          :clearable="clearable && !controlDisabled"
          readonly
          v-on="on"
          @click:clear="onClearButtonPressed"
        >
          <template
            v-if="!hideAppend"
            #append
          >
            <reset-btn
              v-if="cachedValueChanged && controlHasError"
              @click="controlReset"
            />
            <copy-to-clipboard-btn
              v-if="cacheValue"
              :value="{'text/plain': localizedDateValue}"
            />
            <calendar-sync-icon v-if="internalOptions.add_to_calendar" />
          </template>
        </v-text-field>
      </template>

      <v-card>
        <v-card-text class="px-0 py-0">
          <v-tabs
            v-model="activeTab"
            fixed-tabs
          >
            <v-tab key="calendar">
              <v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab key="timer">
              <v-icon>mdi-clock-outline</v-icon>
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker
                v-model="cacheValueDatetime.date"
                first-day-of-week="1"
                scrollable
                @input="activeTab = 1"
              />
            </v-tab-item>
            <v-tab-item key="timer">
              <v-time-picker
                ref="timePicker"
                v-model="cacheValueDatetime.time"
                format="24hr"
                full-width
                scrollable
                @change="onTimeValueChanged"
              />
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions v-if="!selectOnClick">
          <v-spacer />
          <v-btn
            v-if="!hideDeleteBtn"
            color="error"
            text
            @click="$refs.menu.save(helper.iso8601DateObject(null))"
          >
            Löschen
          </v-btn>
          <v-btn
            :disabled="!cachedValueChanged"
            color="primary"
            depressed
            @click="$refs.menu.save(cacheValueDatetime)"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
