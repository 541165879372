import { stateColors } from 'helpers/definitions'
import includes from 'lodash/includes'
import { distanceDate } from 'helpers/date'
import { routeFor } from 'helpers/route'
import globalIdFor from 'helpers/global-id.js'

const TASK_STATE_CREATED = 'created'
const TASK_STATE_SNOOZED = 'snoozed'
const TASK_STATE_ACTIVE = 'active'
const TASK_STATE_COMPLETED = 'completed'
const TASK_STATE_SKIPPED = 'skipped'

const TASK_STATES = [TASK_STATE_CREATED, TASK_STATE_SNOOZED, TASK_STATE_ACTIVE, TASK_STATE_COMPLETED, TASK_STATE_SKIPPED]

export default {
  methods: {
    taskLinkFor (task) {
      return this.taskNoAccessFor(task) ? null : routeFor('task', task.id)
    },
    taskNoAccessFor (task) {
      return task.noAccess === true
    },
    taskHasAvailableActionFor (task, action) {
      return includes(task.availableActions, action)
    },
    taskStateColorFor (task, workflowIsActive) {
      if (workflowIsActive) {
        switch (task.state) {
          case TASK_STATE_CREATED:
            return stateColors.created
          case TASK_STATE_SNOOZED:
            return stateColors.snoozed
          case TASK_STATE_ACTIVE:
            return stateColors.started
          case TASK_STATE_COMPLETED:
            return stateColors.completed
          case TASK_STATE_SKIPPED:
            return stateColors.skipped
          default:
            throw new Error('stateColor: Unsupported task state: ' + this.task.state)
        }
      } else {
        return stateColors.deactivated
      }
    },
    taskGlobalIdFor (task) {
      return globalIdFor('Task', task?.id)
    }
  },
  computed: {
    taskStateText () {
      if (includes(TASK_STATES, this.task.state)) {
        return this.$t('task.state.' + this.task.state)
      } else {
        throw new Error('stateText: Unsupported task state: ' + this.task.state)
      }
    },
    taskIsOpen () {
      return this.task.state === TASK_STATE_CREATED ||
          this.task.state === TASK_STATE_SNOOZED ||
          this.task.state === TASK_STATE_ACTIVE
    },
    taskStateUpdatedAtDate () {
      return this.task.stateUpdatedAt ? new Date(this.task.stateUpdatedAt) : null
    },
    taskDueAtDate () {
      return this.task.dueAt ? new Date(this.task.dueAt) : null
    },
    taskDueDistanceText () {
      return this.$t('task.dueDistance', { distance: distanceDate(this.taskDueAtDate) })
    },
    taskSnoozeUntilDate () {
      return this.task.snoozeUntil ? new Date(this.task.snoozeUntil) : null
    },
    taskSnoozeDistanceText () {
      return this.$t('task.snoozeDistance', { distance: distanceDate(this.taskSnoozeUntilDate) })
    },
    taskIdentifier () {
      return `#${this.task.id}`
    },
    taskIsVariantFull () {
      return this.task?.variant === 'full'
    },
    taskGlobalId () {
      return this.taskGlobalIdFor(this.task)
    }
  }
}
