import {
  CONTENT_TYPE_EMAIL,
  CONTENT_TYPE_FILE, CONTENT_TYPE_PASSWORD, CONTENT_TYPE_PHONE_NUMBER, CONTENT_TYPE_RICHTEXT,
  CONTENT_TYPE_STRING, CONTENT_TYPE_TEXT, CONTENT_TYPE_URL,
  isContentTypeValueEmpty
} from 'helpers/content-types.js'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isNull from 'lodash/isNull'

export const DATA_FIELD_FILTERS_TYPE = 'data-fields-filter'
export default {
  methods: {
    dataFieldFiltersComparatorsFor (contentType, comparators) {
      return map(comparators.contentTypes[contentType], comp => {
        return { value: comp, text: comparators.translations[comp] }
      })
    },
    dataFieldFiltersDefaultComparatorFor (contentType, comparators) {
      return this.dataFieldFiltersComparatorsFor(contentType, comparators)[0].value
    },
    dataFieldFiltersDefaultFor (comparator) {
      return comparator === 'between' ? [null, null] : null
    },
    dataFieldFiltersValueOrDefaultFor (values, field, comparators) {
      const res = find(values, { id: field.id.toString() })
      const defaultComparator = this.dataFieldFiltersDefaultComparatorFor(field.contentType, comparators)

      if (res !== undefined) {
        if (isNull(res.values)) res.values = this.dataFieldFiltersDefaultFor(defaultComparator)
        return res
      }

      return { id: field.id.toString(), comp: defaultComparator, values: this.dataFieldFiltersDefaultFor(defaultComparator) }
    },
    dataFieldFiltersValueFor (filterValue, index) {
      return filterValue.comp === 'between' ? filterValue.values[index] : filterValue.values
    },
    dataFieldFiltersControlLabels (comparator) {
      switch (comparator) {
        case 'between':
          return ['von', 'bis']
        case 'is_empty':
        case 'is_not_empty':
          return []
        default:
          return [null]
      }
    },
    dataFieldFiltersControls (values, field, comparators) {
      const filterValue = this.dataFieldFiltersValueOrDefaultFor(values, field, comparators)
      const controlDefinition = { type: field.contentType, options: field.options, value: filterValue.values }

      if (Object.prototype.hasOwnProperty.call(field.options, 'multiple')) field.options.multiple = true

      switch (field.contentType) {
        case CONTENT_TYPE_FILE:
          controlDefinition.type = CONTENT_TYPE_STRING
          controlDefinition.options = {}
          break
        case CONTENT_TYPE_URL:
        case CONTENT_TYPE_EMAIL:
        case CONTENT_TYPE_PHONE_NUMBER:
        case CONTENT_TYPE_PASSWORD:
        case CONTENT_TYPE_TEXT:
        case CONTENT_TYPE_RICHTEXT:
          controlDefinition.type = CONTENT_TYPE_STRING
          break
      }

      return this.dataFieldFiltersControlLabels(filterValue.comp).map(label => {
        if (isNull(label)) {
          return controlDefinition
        } else {
          return { label, ...controlDefinition }
        }
      })
    },
    dataFieldFiltersCleanupValues (values) {
      return filter(values.map(val => {
        const compCount = this.dataFieldFiltersControlLabels(val.comp).length
        if (compCount === 0) {
          delete val.values
          return val
        }

        return val
      }), val => !isNull(val))
    },
    dataFieldFiltersRemoveWithEmptyValues (values, fields) {
      return filter(values.map(val => {
        const compCount = this.dataFieldFiltersControlLabels(val.comp).length
        const field = find(fields, field => field.id.toString() === val.id)
        if ((field === undefined) ||
          (compCount === 0) ||
          (compCount === 1 && !isContentTypeValueEmpty(field.contentType, val.values)) ||
          (compCount === 2 && (!isContentTypeValueEmpty(field.contentType, val.values[0]) || !isContentTypeValueEmpty(field.contentType, val.values[1])))) {
          return val
        } else {
          return null
        }
      }), val => !isNull(val))
    }
  }
}
