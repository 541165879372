<script>
import { PageContentable } from '../page'
import UsersControl from 'controls/users-control.vue'
import VisibilitySummary from 'components/visibility-summary.vue'
import MentioningHint from 'components/mentioning-hint.vue'
import DesignModeBtn from 'components/design-mode-btn.vue'
import Workflow from 'mixins/models/workflow'
import map from 'lodash/map'
import LabelsControl from 'controls/labels-control.vue'

export default {
  name: 'ProcessEditSidebarRight',
  components: {
    LabelsControl,
    UsersControl,
    VisibilitySummary,
    MentioningHint,
    DesignModeBtn
  },
  mixins: [PageContentable, Workflow],
  props: {
    designMode: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    workflow () {
      return this.value ? this.value.workflow : null
    },
    group_ids () { return map(this.workflow.groups, 'id') }
  },
  methods: {
    onUpdated (process) {
      this.$emit('input', {
        workflow: process,
        task: null
      })
    }
  }
}
</script>
<template>
  <div
    v-if="value"
    :key="`process-${workflow.id}`"
  >
    <users-control
      v-model="workflow.assignee"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.workflows.updateAssignee(workflow.id), mapping: 'assignee'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.users.list(), params: {group_ids: group_ids}}"
      label="Verantwortlich"
      success-message=""
      single
      indent
      sidebar
      @request-success-data="onUpdated"
    />
    <v-divider />

    <labels-control
      v-model="workflow.labels"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.labels.updateAssignment(), mapping: 'labels', params: {object_gid: workflowGlobalId}}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.labels.list()}"
      label="Labels"
      success-message=""
      indent
      sidebar
    />
    <v-divider />

    <visibility-summary :value="workflow.groups" />
    <v-divider />

    <mentioning-hint
      :identifier="workflowIdentifier"
      path="workflow.mentioningHint"
    />
    <v-divider />

    <design-mode-btn
      :value="designMode"
      @input="$emit('update:prop', { prop: 'designMode', value: $event })"
    />
  </div>
</template>
