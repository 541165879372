import { stateColors } from 'helpers/definitions'
import globalIdFor from 'helpers/global-id.js'
import colors from 'vuetify/lib/util/colors'

export default {
  methods: {
    labelGlobalIdFor (label) {
      return globalIdFor('Label', label?.id)
    }
  },
  computed: {
    labelBreadcrumbs () {
      return [
        { text: 'Labels', to: { name: 'labels' } },
        { text: `${this.label.id}` }
      ]
    },
    labelStateText () {
      return 'Aktualisiert'
    },
    labelStateColor () {
      return stateColors.created
    },
    labelStateUpdatedAtDate () {
      return new Date(this.label.updatedAt)
    },
    labelAvailableColors () {
      return Object.keys(colors).filter(color => color !== 'shades').flatMap(color => [colors[color].base, colors[color].lighten2])
    }
  }
}
