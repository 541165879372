<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import CalendarSyncIcon from 'components/calendar-sync-icon.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import { formatDate } from 'helpers/date'
import defaults from 'lodash/defaults'

export default {
  name: 'DateControl',
  components: {
    ResetBtn,
    CopyToClipboardBtn,
    CalendarSyncIcon
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: String,
      default: undefined
    },
    sidebar: Boolean,
    hideDeleteBtn: {
      type: Boolean,
      default: false
    },
    selectOnClick: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuOpen: false
    }
  },
  computed: {
    internalOptions () {
      return defaults(this.options, {
        add_to_calendar: false
      })
    },
    localizedDateValue () {
      if (this.cacheValue) {
        return formatDate(new Date(this.cacheValue))
      } else {
        return ''
      }
    }
  },
  methods: {
    onMenuStateChanged (opened) {
      if (opened) {
        this.controlOnLock()
      } else {
        this.controlOnInput()
        this.controlOnChange()
        this.controlOnUnlock()
      }
    },
    onValueChanged (value) {
      if (this.selectOnClick) this.$refs.menu.save(this.cacheValue)
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    onClearButtonPressed () {
      this.cacheValue = null
      this.onMenuStateChanged(false)
    }
  }
}
</script>
<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuOpen"
      :close-on-content-click="false"
      :return-value.sync="cacheValue"
      min-width="290px"
      offset-y
      transition="scale-transition"
      @input="onMenuStateChanged"
    >
      <template #activator="{ on }">
        <v-input
          v-if="sidebar"
          :label="label"
          :disabled="controlDisabled"
          :dense="controlDense"
          :hide-details="controlHideDetails"
          :class="controlClass"
          :error-messages="controlErrorMessages"
          :error-count="Number.MAX_VALUE"
          :hint="controlHint"
          :persistent-hint="controlPersistentHint"
          :success-messages="controlSuccessMessage"
          class="custom-input-control custom-input-control--sidebar pl-4 pr-2 mt-2"
        >
          <div class="custom-input-control__content mb-2">
            {{ localizedDateValue }}
          </div>
          <v-progress-linear
            v-if="controlLoading"
            absolute
            bottom
            height="2"
            indeterminate
          />
          <template #append>
            <v-btn
              :disabled="controlDisabled"
              color="primary"
              small
              text
              v-on="on"
            >
              {{ $t('general.buttons.change') }}
            </v-btn>
          </template>
        </v-input>

        <v-text-field
          v-else
          :value="localizedDateValue"
          :label="label"
          :loading="controlLoading"
          :disabled="controlDisabled"
          :dense="controlDense"
          :hide-details="controlHideDetails"
          :class="controlClass"
          :error-messages="controlErrorMessages"
          :error-count="Number.MAX_VALUE"
          :hint="controlHint"
          :persistent-hint="controlPersistentHint"
          :success-messages="controlSuccessMessage"
          :clearable="clearable && !controlDisabled"
          readonly
          v-on="on"
          @click:clear="onClearButtonPressed"
        >
          <template
            v-if="!hideAppend"
            #append
          >
            <reset-btn
              v-if="cachedValueChanged && controlHasError"
              @click="controlReset"
            />
            <copy-to-clipboard-btn
              v-if="cacheValue"
              :value="{'text/plain': localizedDateValue}"
            />
            <calendar-sync-icon v-if="internalOptions.add_to_calendar" />
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="cacheValue"
        first-day-of-week="1"
        no-title
        scrollable
        @change="onValueChanged"
      >
        <template v-if="!selectOnClick">
          <v-spacer />
          <v-btn
            v-if="!hideDeleteBtn"
            color="error"
            text
            @click="$refs.menu.save(null)"
          >
            Löschen
          </v-btn>
          <v-btn
            :disabled="!cachedValueChanged"
            color="primary"
            depressed
            @click="$refs.menu.save(cacheValue)"
          >
            Speichern
          </v-btn>
        </template>
      </v-date-picker>
    </v-menu>
  </div>
</template>
