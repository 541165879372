<script>
import DataFieldFilters from 'mixins/models/data-field-filters'
import DataControl from 'controls/data-control'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import findIndex from 'lodash/findIndex'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'

export default {
  name: 'FilterDataFields',
  components: { DataControl },
  mixins: [DataFieldFilters],
  props: {
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    comparators: {
      type: Object,
      required: true
    }
  },
  computed: {
    groups () {
      return uniq(map(this.items, 'grouping'))
    },
    hasGroups () {
      return this.groups.length > 1 || !isNil(this.groups[0])
    }
  },
  methods: {
    fieldsFor (group) {
      return isNil(group) ? this.items : filter(this.items, { grouping: group })
    },
    filterValuesEmpty (values) {
      if (isArray(values)) {
        return values.reduce((acc, value) => { return acc && this.filterValuesEmpty(value) }, true)
      }
      return isNil(values) || (isString(values) && values === '')
    },
    isFilterActive (field) {
      const filterIndex = findIndex(this.value, { id: field.id.toString() })
      if (filterIndex > -1) {
        const filterValue = this.value[filterIndex]
        const filterValuesCount = this.dataFieldFiltersControlLabels(filterValue.comp).length
        return filterValuesCount === 0 || !this.filterValuesEmpty(filterValue.values)
      }
      return false
    },
    resetFilter (field) {
      const filterIndex = findIndex(this.value, { id: field.id.toString() })
      if (filterIndex > -1) {
        this.updateFilterValue(filterIndex, null)
      }
    },
    onComparatorChanged (field, newComparatorValue) {
      const filterIndex = findIndex(this.value, { id: field.id.toString() })
      const filterValue = this.dataFieldFiltersValueOrDefaultFor(this.value, field, this.comparators)

      filterValue.comp = newComparatorValue
      if (filterIndex > -1 && isNil(filterValue.values)) {
        filterValue.values = this.dataFieldFiltersDefaultFor(newComparatorValue)
      }

      this.updateFilterValue(filterIndex, filterValue)
    },
    onFilterDataChanged (field, index, newDataValue) {
      const filterIndex = findIndex(this.value, { id: field.id.toString() })
      const filterValue = this.dataFieldFiltersValueOrDefaultFor(this.value, field, this.comparators)
      if (filterValue.comp === 'between') {
        filterValue.values[index] = newDataValue
      } else {
        filterValue.values = newDataValue
      }

      if (field.contentType === 'dossier') {
        filterValue.values = filterValue.values.map(val => val.id)
      }

      this.updateFilterValue(filterIndex, filterValue)
    },
    updateFilterValue (index, newFilterValue) {
      const newValue = this.value

      if (index === -1) {
        newValue.push(newFilterValue)
      } else {
        if (isNil(newFilterValue)) {
          newValue.splice(index, 1)
        } else {
          newValue[index] = newFilterValue
        }
      }

      this.$emit('input', newValue)
    }
  }
}
</script>
<template>
  <div>
    <div
      v-for="(group, indexGroup) in groups"
      :key="hasGroups ? group : 'main'"
    >
      <span
        v-if="hasGroups"
        class="text-subtitle-1 black--text"
        style="font-size: 1.125rem !important;"
      >{{ group }}</span>

      <div
        v-for="(field, indexField) in fieldsFor(group)"
        :key="field.id"
        :class="{'pt-6': indexField > 0}"
      >
        <div class="d-flex align-center mr-2 mb-2">
          <span class="text--secondary">{{ field.label }}</span>
          <v-btn
            v-if="isFilterActive(field)"
            icon
            plain
            small
            class="ml-auto"
            @click="resetFilter(field)"
          >
            <v-icon>mdi-undo</v-icon>
          </v-btn>
        </div>

        <v-container class="pa-0">
          <v-row>
            <v-col
              :md="4"
              :cols="12"
            >
              <v-select
                :value="dataFieldFiltersValueOrDefaultFor(value, field, comparators).comp"
                :items="dataFieldFiltersComparatorsFor(field.contentType, comparators)"
                hide-details
                dense
                :class="{'data-field-filter--inactive': !isFilterActive(field) }"
                @change="onComparatorChanged(field, $event)"
              />
            </v-col>
            <v-col
              v-for="(controlDef, index) in dataFieldFiltersControls(value, field, comparators)"
              :key="`filter-control-${field.id}-${index}`"
              :md="8 / dataFieldFiltersControls(value, field, comparators).length"
              :cols="12 / dataFieldFiltersControls(value, field, comparators).length"
            >
              <data-control
                :value="dataFieldFiltersValueFor(dataFieldFiltersValueOrDefaultFor(value, field, comparators), index)"
                :type="controlDef.type"
                :options="controlDef.options"
                :label="controlDef.label"
                hide-append
                dense
                hide-details
                select-on-click
                clearable
                :class="{'data-field-filter--inactive': !isFilterActive(field) }"
                @change="onFilterDataChanged(field, index, $event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-divider
        v-if="hasGroups && (indexGroup < groups.length - 1)"
        class="mt-8 mb-4"
      />
    </div>
  </div>
</template>
<style lang="scss">
  .data-field-filter--inactive:not(.v-input--is-focused) {
    opacity: 0.4;
  }
</style>
