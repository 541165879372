<script>
import ListItemTemplate from 'list-items/list-item-template.vue'
import { isDarkColor } from 'helpers/color'

export default {
  name: 'LabelListItem',
  components: { ListItemTemplate },
  props: {
    ...ListItemTemplate.props,
    value: {
      type: Object,
      required: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showSubtitle () {
      return this.showDescription && !!this.value?.description?.length
    },
    hasDarkColor () {
      return isDarkColor(this.value?.color)
    }
  }
}
</script>
<template>
  <list-item-template
    :skeleton-type="showSubtitle ? 'list-item-avatar-two-line' : 'list-item'"
    :two-line="showSubtitle"
    v-bind="$props"
    v-on="$listeners"
  >
    <v-list-item-content>
      <v-list-item-title>
        <v-chip
          :id="$id('chip')"
          :color="value.color"
          :dark="hasDarkColor"
        >
          {{ value.title }}
        </v-chip>
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="showSubtitle"
        class="mt-2"
      >
        {{ value.description }}
      </v-list-item-subtitle>

      <v-tooltip
        v-if="tooltip && value.description && value.description.length"
        :activator="$idRef('chip')"
        left
        max-width="40rem"
        open-delay="1000"
      >
        <div>{{ value.description }}</div>
      </v-tooltip>
    </v-list-item-content>
  </list-item-template>
</template>
