function hexToRgb (hex) {
  let r = 0
  let g = 0
  let b = 0
  if (hex?.length === 4) {
    r = '0x' + hex[1] + hex[1]
    g = '0x' + hex[2] + hex[2]
    b = '0x' + hex[3] + hex[3]
  } else if (hex?.length === 7) {
    r = '0x' + hex[1] + hex[2]
    g = '0x' + hex[3] + hex[4]
    b = '0x' + hex[5] + hex[6]
  }
  return [+r, +g, +b]
}
export function isDarkColor (hexColor) {
  if (!hexColor) return false

  const rgb = hexToRgb(hexColor)
  const sum = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000)
  return (sum < 128)
}
