import DossierListPage from './list'
import ListPage from '../list-page'
import { DATA_FIELD_FILTERS_TYPE } from 'mixins/models/data-field-filters.js'

export default {
  name: 'DossierListByDefinitionPage',
  mixins: [DossierListPage],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    initPageRequestParams () {
      const params = DossierListPage.methods.initPageRequestParams.call(this)
      delete params.definition_ids
      params.definition_id = this.id
      return params
    },
    filterFields () {
      const filterFields = DossierListPage.methods.filterFields.call(this)
      return filterFields.map(row => row.filter((element) => element.name !== 'definition_ids')).filter(row => row.length > 0)
    },
    advancedFilterFilters () {
      return [{
        name: 'fields',
        type: DATA_FIELD_FILTERS_TYPE,
        items: this.valueAttributeOrDefault('fieldDefinitions'),
        comparators: this.valueAttributeOrDefault('comparators', {}),
        default: [],
        cast: (values) => [values].flat().map(val => Object.assign({}, val))
      }]
    },
    activeFiltersKey () {
      return `${ListPage.methods.activeFiltersKey.call(this)}-${this.id}`
    }
  },
  watch: {
    id: {
      handler (value, oldValue) {
        const initData = this.pageInitData()
        this.filters = initData.filters
        this.pages = initData.pages
      }
    }
  }
}
