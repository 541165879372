<script>
import ListItemTemplate from './list-item-template.vue'
import Labels from 'components/labels'

export default {
  name: 'DossierListItem',
  components: { Labels, ListItemTemplate },
  props: {
    ...ListItemTemplate.props
  }
}
</script>
<template>
  <div>
    <list-item-template
      skeleton-type="list-item-avatar-two-line"
      two-line
      v-bind="$props"
      v-on="$listeners"
    >
      <v-list-item-content>
        <v-list-item-title :class="{'text--disabled': value.deleted}">
          {{ value.deleted ? $t('dossier.deletedTitle') : value.title }}
        </v-list-item-title>
        <v-list-item-subtitle :class="{'text--disabled': value.deleted}">
          {{ (!value.deleted && value.subtitle) ? value.subtitle : `*${value.id}` }}
        </v-list-item-subtitle>
        <labels
          :value="value.labels"
          wrapper="v-list-item-subtitle"
          small
          class="mt-1"
        />
      </v-list-item-content>

      <v-list-item-action v-if="value.definition">
        <v-list-item-action-text
          class="text-right"
          style="width: min-content;"
        >
          {{ value.definition.name }}
        </v-list-item-action-text>
      </v-list-item-action>
    </list-item-template>

    <v-list-item-subtitle
      v-if="$scopedSlots.snippets"
      class="mt-2 ml-4"
    >
      <slot name="snippets" />
    </v-list-item-subtitle>
  </div>
</template>
