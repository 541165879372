<script>
import FilterTabs from './filter-tabs.vue'
import FilterItem from 'components/data-list/filter-item.vue'
import AdvancedFilterDialog from 'dialogs/advanced-filter-dialog.vue'
import { DATA_FIELD_FILTERS_TYPE } from 'mixins/models/data-field-filters'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'

export default {
  name: 'DataListFilter',
  components: {
    AdvancedFilterDialog,
    FilterTabs,
    FilterItem
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasAdvancedFilters () {
      return isArray(this.value.advancedFilters) && this.value.advancedFilters.length > 0
    },
    activeAdvancedFilters () {
      return this.value.flattenedCleanedAdvancedFilters.map(filter => {
        const activeFilter = this.value.active.find(el => el.name === filter.name)
        if (activeFilter === undefined) return undefined

        const clone = cloneDeep(filter)

        if (filter.type === DATA_FIELD_FILTERS_TYPE) {
          clone.items = filter.items()
            .filter(item => activeFilter.activeFieldIds.indexOf(item.id.toString()) > -1)
            .map(item => { const clonedItem = cloneDeep(item); delete clonedItem.grouping; return clonedItem })
        }

        return clone
      }).filter(filter => filter !== undefined)
    }
  },
  methods: {
    valueFromFunction (value) {
      return isFunction(value) ? value() : value
    },
    updateFilter ({ value, field }) {
      this.$emit('update:filter', { value, field })
    },
    colSize (field, cols) {
      if (has(field, 'cols')) return field.cols
      if (cols.length === 1) return 12

      const autoColsCount = cols.filter(f => !has(f, 'cols')).length
      const colsSum = cols.reduce((acc, f) => {
        return acc + (has(f, 'cols') ? f.cols : 0)
      }, 0)

      return (12 - colsSum) / autoColsCount
    }
  }
}
</script>
<template>
  <v-card
    class="mt-4"
    :class="{'mb-3 pb-1': hasAdvancedFilters}"
    elevation="0"
    outlined
  >
    <filter-tabs
      v-if="value.tabs"
      :key="value.tabs.name"
      :value="value.values[value.tabs.name]"
      :items="value.tabs.items"
      :counts="valueFromFunction(value.tabs.counts)"
      @input="updateFilter({ value: $event, field: value.tabs.name})"
    />

    <v-container
      v-if="value.fields && value.fields.length"
      class="my-3 px-4"
    >
      <v-row
        v-for="(cols, rowIndex) in value.fields"
        :key="rowIndex"
      >
        <v-col
          v-for="(field, colIndex) in cols"
          :key="colIndex"
          class="pa-2"
          :sm="colSize(field, cols)"
          :cols="12"
        >
          <filter-item
            :filter="field"
            :value="value.values[field.name]"
            @update:filter="updateFilter"
          />
        </v-col>
      </v-row>

      <v-row
        v-for="filter in activeAdvancedFilters"
        :key="filter.name"
      >
        <v-col class="pa-2">
          <filter-item
            :filter="filter"
            :value="value.values[filter.name]"
            @update:filter="updateFilter"
          />
        </v-col>
      </v-row>
    </v-container>

    <advanced-filter-dialog
      v-if="hasAdvancedFilters"
      :filters="value.advancedFilters"
      :value="value.values"
      @update:filters="updateFilter({ value: $event, field: undefined })"
    >
      <template #activator="{ on }">
        <v-btn
          color="primary"
          plain
          rounded
          absolute
          right
          style="bottom: 0; transform: translateY(50%); background-color: white;"
          v-on="on"
        >
          Erweiterte Filter
        </v-btn>
      </template>
    </advanced-filter-dialog>
  </v-card>
</template>
