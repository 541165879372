import { VChip, VTooltip } from 'vuetify/lib/components'
import { isDarkColor } from 'helpers/color'
import isArray from 'lodash/isArray'
export default {
  name: 'Labels',
  functional: true,
  props: {
    value: Array,
    wrapper: String,
    small: Boolean,
    tooltip: Boolean
  },
  render: function (createElement, context) {
    function genContent (createElement, context) {
      return context.props.value.flatMap(label => {
        const res = []

        res.push(
          createElement(VChip, {
            class: 'mr-1 mb-2',
            key: label.id,
            props: {
              small: context.props.small,
              color: label.color,
              dark: isDarkColor(label.color)
            },
            attrs: {
              id: context.parent.$id('label-' + label.id)
            }
          }, label.title)
        )

        if (context.props.tooltip && label?.description?.length) {
          res.push(
            createElement(VTooltip, {
              props: {
                bottom: true,
                maxWidth: '40rem',
                activator: context.parent.$idRef('label-' + label.id)
              }
            }, label.description)
          )
        }

        return res
      })
    }

    if (isArray(context.props.value) && context.props.value.length) {
      return createElement(context.props.wrapper || 'div', {
        staticClass: 'd-flex flex-wrap mb-n2 ' + (context.data.staticClass || ''),
        class: context.data.class
      }, genContent(createElement, context))
    } else {
      return null
    }
  }
}
