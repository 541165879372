import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import { routeFor } from 'helpers/route'
import eventBus from 'helpers/event-bus'

import DashboardPage from 'pages/dashboard/index'

import TaskListPage from 'pages/tasks/list'
import TaskListByDefinitionPage from 'pages/tasks/list-by-definition'
import TaskOrProcessEditPage from 'pages/tasks/edit'

import ProcessDefinitionListPage from 'pages/process-definitions/list'
import ProcessDefinitionEditPage from 'pages/process-definitions/edit'

import DossierListPage from 'pages/dossiers/list'
import DossierListByDefinitionPage from 'pages/dossiers/list-by-definition'
import DossierEditPage from 'pages/dossiers/edit'

import DossierDefinitionListPage from 'pages/dossier-definitions/list'
import DossierDefinitionEditPage from 'pages/dossier-definitions/edit'

import AutomationListPage from 'pages/automations/list'
import AutomationEditPage from 'pages/automations/edit'

import UserProfilePage from 'pages/users/profile'
import UserListPage from 'pages/users/list'
import UserSettingsPage from 'pages/users/settings.js'

import GroupEditPage from 'pages/groups/edit'
import GroupListPage from 'pages/groups/list'

import StaticPageEditPage from 'pages/static-pages/edit'
import StaticPageListPage from 'pages/static-pages/list'

import FooterMenuEntryEditPage from 'pages/footer-menu-entries/edit'
import FooterMenuEntryListPage from 'pages/footer-menu-entries/list'

import MainMenuEntryListPage from 'pages/main-menu-entries/list'

import NotificationListPage from 'pages/notifications/list'
import JournalListPage from 'pages/journal/list'
import SearchListPage from 'pages/search/list'

import ShareShowPage from 'pages/shares/show'
import ShareShowContentFooter from 'pages/shares/show-content-footer.vue'

import LabelListPage from 'pages/labels/list'
import LabelEditPage from 'pages/labels/edit'

import AboutPage from 'pages/about/index'

import SignInPage from 'pages/authentication/sign-in.vue'
import LostPasswordPage from 'pages/authentication/lost-password.vue'
import ChangePasswordPage from 'pages/authentication/change-password.vue'
import ConfirmationPage from 'pages/authentication/confirmation.vue'

import StaticPagesShowPage from 'pages/static-pages/show'
import Error404Page from 'pages/errors/404'

// Catch vue-router error NavigationDuplicated
['push', 'replace'].forEach(method => {
  const originalMethod = VueRouter.prototype[method]
  VueRouter.prototype[method] = function m (location) {
    return originalMethod.call(this, location).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }
})

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    // Please double check the URLs generated in app/helpers/email_helper.rb when changing URLs here. Especially /notifications, settings, and details pages for users, worflows, or tasks.
    {
      path: '/dashboard',
      name: 'left-here-to-keep-tests-happy-until-labels-are-integrated',
      component: DashboardPage
    },
    {
      path: '/users/sign-in',
      name: 'sign-in-page',
      component: SignInPage,
      meta: { hasFooter: true, requiresAuth: false }
    },
    {
      path: '/users/lost-password',
      name: 'lost-password-page',
      component: LostPasswordPage,
      meta: { hasFooter: true, requiresAuth: false }
    },
    {
      path: '/users/change-password',
      name: 'change-password-page',
      component: ChangePasswordPage,
      meta: { hasFooter: true, requiresAuth: false }
    },
    {
      path: '/users/confirmation',
      name: 'confirmation-page',
      component: ConfirmationPage,
      meta: { hasFooter: true, requiresAuth: false }
    },
    {
      path: '/',
      redirect: { name: 'dashboard' },
      name: 'home'
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardPage
    },
    {
      path: '/workflows/:id',
      name: 'workflow',
      component: TaskOrProcessEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'workflow'
      })
    },
    {
      path: '/tasks/:id',
      name: 'task',
      component: TaskOrProcessEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'task'
      })
    },
    {
      path: '/tasks/by-definition/:id',
      name: 'tasks-by-definition',
      component: TaskListByDefinitionPage,
      props: (route) => ({
        id: route.params.id,
        query: route.query
      })
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: TaskListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/process-definitions/:id/task-definitions/:taskDefinitionId',
      name: 'task-definition',
      component: ProcessDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'task-definition',
        subId: parseInt(route.params.taskDefinitionId)
      })
    },
    {
      path: '/process-definitions/:id/block-definitions/:blockDefinitionId',
      name: 'block-definition',
      component: ProcessDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'block-definition',
        subId: parseInt(route.params.blockDefinitionId)
      })
    },
    {
      path: '/process-definitions/:id',
      name: 'process-definition',
      component: ProcessDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'process-definition'
      })
    },
    {
      path: '/process-definitions',
      name: 'process-definitions',
      component: ProcessDefinitionListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/dossiers/:id/task-references',
      name: 'dossier-task-references',
      component: DossierEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'task-references'
      })
    },
    {
      path: '/dossiers/:id/dossier-field-references/:fieldId',
      name: 'dossier-dossier-field-references',
      component: DossierEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'dossier-field-references',
        fieldId: parseInt(route.params.fieldId)
      })
    },
    {
      path: '/dossiers/:id',
      name: 'dossier',
      component: DossierEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'main'
      })
    },
    {
      path: '/dossiers/by-definition/:id',
      name: 'dossiers-by-definition',
      component: DossierListByDefinitionPage,
      props: (route) => ({
        id: route.params.id,
        query: route.query
      })
    },
    {
      path: '/dossiers',
      name: 'dossiers',
      component: DossierListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/dossier-definitions/:id/groups',
      name: 'dossier-definition-groups',
      component: DossierDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'groups'
      })
    },
    {
      path: '/dossier-definitions/:id/references',
      name: 'dossier-definition-references',
      component: DossierDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'references'
      })
    },
    {
      path: '/dossier-definitions/:id/data-transformations/:dataTransformationId',
      name: 'dossier-data-transformation-detail',
      component: DossierDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        dataTransformationId: parseInt(route.params.dataTransformationId),
        type: 'data-transformation-detail'
      })
    },
    {
      path: '/dossier-definitions/:id/data-transformations',
      name: 'dossier-data-transformations',
      component: DossierDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'data-transformations'
      })
    },
    {
      path: '/dossier-definitions/:id',
      name: 'dossier-definition',
      component: DossierDefinitionEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'main'
      })
    },
    {
      path: '/dossier-definitions',
      name: 'dossier-definitions',
      component: DossierDefinitionListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/automations/:id',
      name: 'automation',
      component: AutomationEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'main'
      })
    },
    {
      path: '/automations',
      name: 'automations',
      component: AutomationListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/users/:id/settings/groups',
      name: 'user-settings-groups',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'groups'
      })
    },
    {
      path: '/users/:id/settings/email',
      name: 'user-settings-email',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'email'
      }),
      beforeEnter: (to, from, next) => {
        // Prevent routing to password page of other users for non admins
        const userId = parseInt(to.params.id)
        if (Vue.$config.isCurrentUser({ id: userId }) || Vue.$config.current_user.isAdmin) {
          next()
        } else next(routeFor('user_settings', userId))
      }
    },
    {
      path: '/users/:id/settings/password',
      name: 'user-settings-password',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'password'
      }),
      beforeEnter: (to, from, next) => {
        // Prevent routing to password page of other users
        const userId = parseInt(to.params.id)
        if (!Vue.$config.isCurrentUser({ id: userId })) {
          next(routeFor('user_settings', userId))
        } else next()
      }
    },
    {
      path: '/users/:id/settings/notifications',
      name: 'user-settings-notifications',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'notifications'
      })
    },
    {
      path: '/users/:id/settings/calendar',
      name: 'user-settings-calendar',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'calendar'
      })
    },
    {
      path: '/users/:id/settings/external-api',
      name: 'user-settings-external-api',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'external-api'
      })
    },
    {
      path: '/users/:id/settings',
      name: 'user-settings',
      component: UserSettingsPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'account'
      })
    },
    {
      path: '/users/:id',
      name: 'user',
      component: UserProfilePage,
      props: (route) => ({
        id: parseInt(route.params.id)
      })
    },
    {
      path: '/users',
      name: 'users',
      component: UserListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/groups/:id/users',
      name: 'group-users',
      component: GroupEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'users'
      })
    },
    {
      path: '/groups/:id/process-definitions',
      name: 'group-process-definitions',
      component: GroupEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'process-definitions'
      })
    },
    {
      path: '/groups/:id/dossier-definitions',
      name: 'group-dossier-definitions',
      component: GroupEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'dossier-definitions'
      })
    },
    {
      path: '/groups/:id',
      name: 'group',
      component: GroupEditPage,
      props: (route) => ({
        id: parseInt(route.params.id),
        type: 'main'
      })
    },
    {
      path: '/groups',
      name: 'groups',
      component: GroupListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: NotificationListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/labels/:id',
      name: 'label',
      component: LabelEditPage,
      props: (route) => ({
        id: parseInt(route.params.id)
      })
    },
    {
      path: '/labels',
      name: 'labels',
      component: LabelListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/journal',
      name: 'journal',
      component: JournalListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/static-page/:id',
      name: 'static-page',
      component: StaticPageEditPage,
      props: (route) => ({
        id: parseInt(route.params.id)
      })
    },
    {
      path: '/static-pages',
      name: 'static-pages',
      component: StaticPageListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/footer-menu-entry/:id',
      name: 'footer-menu-entry',
      component: FooterMenuEntryEditPage,
      props: (route) => ({
        id: parseInt(route.params.id)
      })
    },
    {
      path: '/footer-menu-entries',
      name: 'footer-menu-entries',
      component: FooterMenuEntryListPage,
      props: (route) => ({
        query: route.query
      })
    },
    {
      path: '/main-menu-entries',
      name: 'main-menu-entries',
      component: MainMenuEntryListPage
    },
    {
      path: '/search/:query',
      name: 'search',
      component: SearchListPage,
      props: (route) => ({
        query: route.params.query
      })
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage
    },
    {
      path: '/share/:token',
      name: 'share-page-show',
      component: ShareShowPage,
      props: (route) => ({
        token: route.params.token
      }),
      meta: { hasHeader: false, hasFooter: ShareShowContentFooter, requiresAuth: false }
    },
    {
      path: '/p/:slug',
      name: 'static-page-show',
      component: StaticPagesShowPage,
      props: (route) => ({
        slug: route.params.slug
      }),
      meta: { hasFooter: true, requiresAuth: false }
    },
    {
      path: '*',
      component: Error404Page,
      meta: { requiresAuth: false }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return new Promise((resolve, reject) => {
        eventBus.$off('trigger-scroll')
        eventBus.$on('trigger-scroll', () => {
          setTimeout(() => {
            resolve({
              selector: to.hash,
              offset: { x: 0, y: 120 },
              behavior: 'smooth'
            })
          }, 100)
        })
      }
      )
    } else {
      return { x: 0, y: 0 }
    }
  },
  // set custom query resolver
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    delete query.__ts__ // fix to update vue router query with arrays (see list-page.js)
    delete query.__hidden__ // helper
    const res = qs.stringify(query, { encodeValuesOnly: true })
    return res ? `?${res}` : ''
  }
})

router.beforeEach((to, from, next) => {
  if (to?.meta) {
    if (!Object.prototype.hasOwnProperty.call(to.meta, 'hasHeader')) {
      to.meta.hasHeader = true
    }
    if (!Object.prototype.hasOwnProperty.call(to.meta, 'hasFooter')) {
      to.meta.hasFooter = false
    }
    if (!Object.prototype.hasOwnProperty.call(to.meta, 'requiresAuth')) {
      to.meta.requiresAuth = true
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (Vue.$config.isAuthenticated()) {
      next()
      return
    }
    next({ name: 'sign-in-page' })
  } else {
    next()
  }
})

export default router
