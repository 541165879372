// Styles
import 'controls/richtextarea/richtextarea.sass'

// Extensions
import Richtextarea from 'controls/richtextarea/richtextarea.js'

// Components
import { TiptapVuetify } from 'tiptap-vuetify'
import ActivityHubTimeTrackingWidget from './activity-hub-timetracking-widget.vue'

// Utilities
import mixins from 'vuetify/lib/util/mixins'
const baseMixins = mixins(Richtextarea)

/* @vue/component */
export default baseMixins.extend({
  name: 'ActivityHubCommentEditor',
  props: {
    gid: {
      type: String,
      default: undefined
    },
    timeTracking: {
      type: Boolean,
      default: false
    },
    timeTrackingValue: {
      type: Object,
      default: undefined
    }
  },
  watch: {
    internalValue: {
      handler (value, oldValue) {
        this.storeValueInLocalStorage(value, this.timeTrackingValue)
      },
      deep: true,
      immediate: false
    },
    timeTrackingValue: {
      handler (value, oldValue) {
        this.storeValueInLocalStorage(this.internalValue, value)
      },
      deep: true,
      immediate: false
    }
  },
  mounted () {
    this.$nextTick(() => this.loadValueFromLocalStorage())
  },
  methods: {
    loadComments () {
      return JSON.parse(window.localStorage.getItem('comments')) || {}
    },
    loadValueFromLocalStorage () {
      if (this.gid) {
        const comment = this.loadComments()[this.gid]
        if (comment) {
          if (comment.value) this.internalValue = comment.value
          if (comment.timeTrackingValue) this.$emit('update:timeTrackingValue', comment.timeTrackingValue)
        }
      }
    },
    storeValueInLocalStorage (value, timeTrackingValue) {
      if (this.gid) {
        const comments = this.loadComments()
        if (value || timeTrackingValue) {
          comments[this.gid] = {
            ...(value ? { value } : {}),
            ...(timeTrackingValue ? { timeTrackingValue } : {})
          }
        } else {
          delete comments[this.gid]
        }
        window.localStorage.setItem('comments', JSON.stringify(comments))
      }
    },
    genContent () {
      return [this.$createElement(TiptapVuetify, {
        props: {
          value: this.value,
          extensions: this.extensions,
          placeholder: this.placeholder,
          cardProps: { flat: false, loading: this.loading, disabled: this.disabled },
          disabled: this.disabled,
          nativeExtensions: [this.mentionableExtension],
          editorProperties: this.editorProperties
        },
        on: {
          init: this.onEditorInit,
          input: this.onEditorInput,
          focus: this.onEditorFocus,
          blur: this.onEditorBlur
        },
        attrs: {
          ...this.attrs$,
          id: this.computedId
        },
        class: 'mb-2',
        ref: 'tiptapVuetify'
      }),
      ...(this.timeTracking
        ? [
            this.$createElement(ActivityHubTimeTrackingWidget, {
              props: { value: this.timeTrackingValue },
              on: { change: (value) => this.$emit('update:timeTrackingValue', value) }
            })
          ]
        : []),
      this.errorMessages
        ? this.$createElement('div', {
          class: 'text-caption error--text'
        }, this.errorMessages)
        : this.$createElement('div', {
          class: 'text-caption secondary--text text--lighten-4'
        }, this.hint),
      this.genMentionMenu()
      ]
    }
  },
  render (h) {
    return h('div', {
      class: 'activity-hub-comment-editor'
    }, this.genContent())
  }
})
