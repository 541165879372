import { routeFor } from 'helpers/route'
import globalIdFor from 'helpers/global-id.js'

export default {
  methods: {
    dossierGlobalIdFor (dossier) {
      return globalIdFor('Dossier', dossier?.id)
    }
  },
  computed: {
    dossierBreadcrumbs () {
      return [
        { text: 'Dossiers', to: { name: 'dossiers' } },
        { text: this.dossier.definition.name, to: routeFor('dossiers_by_definition', this.dossier.definition.id) }
      ]
    },
    dossierIdentifier () {
      return `*${this.dossier.id}`
    },
    dossierGlobalId () {
      return this.dossierGlobalIdFor(this.dossier)
    }
  }
}
