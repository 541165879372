<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import DataField from 'mixins/models/data-field'
import CustomDialog from 'dialogs/custom-dialog.vue'
import ContentTypeOptionsControl from 'controls/content-type-options-control.vue'
import DataControl from 'controls/data-control'
import {
  CONTENT_TYPE_RICHTEXT,
  CONTENT_TYPE_NOTE,
  CONTENT_TYPE_DOSSIER,
  updateValueForContentType,
  contentTypeValuesEqual,
  contentTypeOptionsEqual
} from 'helpers/content-types'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'

const STEPS = {
  ACTION_SELECT: 1,
  CREATE_REFERENCE: 2,
  CREATE_NEW: 3
}
export default {
  name: 'AddEditFieldDialog',
  components: {
    DataControl,
    CustomDialog,
    ContentTypeOptionsControl
  },
  mixins: [Requestable, DataField],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          required: false,
          infobox: false,
          type: '',
          options: {},
          value: null
        }
      }
    },
    contentItems: {
      type: Array,
      default: () => undefined
    },
    contentTypes: {
      type: Array,
      default: () => undefined
    },
    preselectedFieldType: {
      type: String,
      default: undefined,
      validator (value) {
        return ['newField', 'existingField', 'noteField'].includes(value)
      }
    },
    isDefinition: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      internalValue: undefined,
      step: STEPS.ACTION_SELECT,
      contentItem: null
    }
  },
  computed: {
    STEPS () {
      return STEPS
    },
    isAdd () {
      return this.$options.propsData.value === undefined
    },
    hasPreselectedFieldType () {
      return this.preselectedFieldType !== undefined
    },
    isNote () {
      return this.internalValue.type === CONTENT_TYPE_NOTE
    },
    selectedContentType () {
      return this.getContentTypeDefinition(this.internalValue?.type)
    },
    selectedContentItem () {
      return find(this.contentItems, { id: this.contentItem })
    },
    selectedContentItemType () {
      return find([...this.contentTypesInternal, ...this.contentTypesInternalFiltered], { type: this.selectedContentItem?.type.name })
    },
    fieldTitle () {
      if (!this.isNote) {
        switch (this.step) {
          case STEPS.CREATE_REFERENCE:
            return this.selectedContentItem.label
          case STEPS.CREATE_NEW:
            return this.internalValue.name
        }
      }
      return undefined
    },
    hasValidChanges () {
      switch (this.step) {
        case STEPS.CREATE_REFERENCE:
          return this.contentItem !== null
        case STEPS.CREATE_NEW:
          return this.internalValue && (
            this.internalValue.name !== this.value.name ||
                    this.internalValue.required !== this.value.required ||
                    this.internalValue.infobox !== this.value.infobox ||
                    this.internalValue.type !== this.value.type ||
                    !contentTypeOptionsEqual(this.internalValue.options, this.value.options) ||
                    !contentTypeValuesEqual(this.value.type, this.value.value, this.internalValue.type, this.internalValue.value)
          ) && ((this.isNote && this.internalValue.value && !this.$refs?.dataControl?.isEmpty) || (!this.isNote && this.internalValue.name && this.internalValue.name.trim().length > 0)) &&
                this.internalValue.type && this.internalValue.type.trim().length > 0 &&
                this.dataFieldAllOptionsValid(this.selectedContentType.options_definition, this.internalValue.options)
        default:
          return false
      }
    },
    requestData () {
      const result = { content_item: {}, task_item: {} }

      switch (this.step) {
        case STEPS.CREATE_REFERENCE:
          return {
            content_item: { id: this.contentItem },
            task_item: {
              required: this.internalValue.required,
              info_box: this.internalValue.infobox,
              add_to_calendar: this.internalValue.options.add_to_calendar
            }
          }
        case STEPS.CREATE_NEW:
          if (this.internalValue && this.contentTypesInternal) {
            if (!this.isNote && (this.isAdd || this.internalValue.name !== this.value.name)) result.content_item.label = this.internalValue.name
            if (this.isAdd || this.internalValue.type !== this.value.type) result.content_item.content_type = this.internalValue.type
            if (this.isAdd || this.internalValue.value !== this.value.value) {
              if (this.isDefinition) {
                result.content_item.default_value = updateValueForContentType(this.internalValue.type, this.internalValue.value)
              } else if (this.isNote) {
                result.content_item.value = this.internalValue.value
              }
            }

            const oldOptionsForRequest = this.dataFieldOptionForRequest(this.getContentTypeDefinition(this.value.type)?.options_definition, this.value.options)
            const newOptionsForRequest = this.dataFieldOptionForRequest(this.selectedContentType.options_definition, this.internalValue.options, this.value.options)
            if (this.isAdd || !contentTypeOptionsEqual(newOptionsForRequest, oldOptionsForRequest)) result.content_item.options = newOptionsForRequest

            if (this.isAdd || this.internalValue.required !== this.value.required) result.task_item.required = this.internalValue.required
            if (this.isAdd || this.internalValue.infobox !== this.value.infobox) result.task_item.info_box = this.internalValue.infobox
            if (this.isAdd || this.internalValue.options.add_to_calendar !== this.value.options.add_to_calendar) result.task_item.add_to_calendar = this.internalValue.options.add_to_calendar
          }
      }

      return result
    },
    contentTypesInternal () {
      return this.contentTypes ? filter(this.contentTypes, (value) => value.type !== CONTENT_TYPE_NOTE) : []
    },
    contentTypesInternalFiltered () {
      return this.contentTypes ? filter(this.contentTypes, ['type', CONTENT_TYPE_NOTE]) : []
    },
    showDataControl () {
      return (this.isDefinition || this.isNote) && this.internalValue.type && (this.internalValue.type !== CONTENT_TYPE_DOSSIER || this.internalValue.options.type !== null)
    }
  },
  methods: {
    initData (note) {
      this.internalValue = cloneDeep(this.value)
      this.contentItem = null
      if (note) this.internalValue.type = CONTENT_TYPE_NOTE
    },
    onDialogOpened () {
      this.initData(false)

      if (this.preselectedFieldType) {
        switch (this.preselectedFieldType) {
          case 'newField':
            this.step = STEPS.CREATE_NEW
            break
          case 'existingField':
            this.step = STEPS.CREATE_REFERENCE
            break
          case 'noteField':
            this.createNewNote()
            break
        }
      } else {
        this.step = this.isAdd ? STEPS.ACTION_SELECT : STEPS.CREATE_NEW
      }

      this.resetRequestable()

      this.$emit('open')
    },
    onRequestSuccess (data) {
      if (this.addAnother) {
        this.successMessage = this.$t(`task.addEditFieldDialog.successMessages.${this.isNote ? 'noteField' : 'dataField'}`, { title: this.fieldTitle })
        this.initData(this.isNote)
        this.onContentTypeChanged()
      } else {
        this.dialogOpen = false
      }

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    gotoStep (step) {
      this.successMessage = undefined
      this.step = step

      if (step === STEPS.ACTION_SELECT) {
        this.internalValue.type = ''
        this.onContentTypeChanged()
      }
    },
    onContentTypeChanged () {
      this.internalValue.options = {}
      if (this.selectedContentType?.options_definition) {
        this.internalValue.options = this.dataFieldGenerateObjectWithDefaultValues(this.selectedContentType.options_definition)
      }
    },
    getContentTypeDefinition (type) {
      return find([...this.contentTypesInternal, ...this.contentTypesInternalFiltered], { type })
    },
    createNewNote () {
      this.internalValue.type = CONTENT_TYPE_NOTE
      this.onContentTypeChanged()
      this.gotoStep(STEPS.CREATE_NEW)
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      setTimeout(() => {
        this.request(this.requestParameter, null, this.requestData)
      }, 50)
    },
    dataControlType (type) {
      return type === CONTENT_TYPE_NOTE ? CONTENT_TYPE_RICHTEXT : type
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t(`task.addEditFieldDialog.title.${isAdd ? 'add' : 'edit'}`)"
    fullheight
    :close-on-button-click="false"
    :cancel-btn-text="$t(`general.buttons.${(step === STEPS.ACTION_SELECT || !isAdd || hasPreselectedFieldType) ? 'cancel' : 'back'}`)"
    :ok-btn-text="step === STEPS.ACTION_SELECT ? null : $t('general.buttons.save')"
    :ok-btn-disabled="!hasValidChanges"
    :additional-ok-btn-texts="isAdd ? [$t('task.addEditFieldDialog.buttons.saveAndCreateAnother')] : undefined"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    :success-message="successMessage"
    @click-ok="onOkButtonClicked"
    @click-cancel="(step === STEPS.ACTION_SELECT || !isAdd || hasPreselectedFieldType) ? dialogOpen = false : gotoStep(STEPS.ACTION_SELECT)"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-window
      v-if="dialogOpen"
      v-model="step"
      style="overflow: visible;"
    >
      <v-window-item :value="STEPS.ACTION_SELECT">
        <v-container>
          <v-row class="mx-n6">
            <v-col>
              {{ preselectedFieldType }}
              <v-btn
                block
                x-large
                color="primary"
                class="floating-text-btn"
                style="height: 100px;"
                @click="gotoStep(STEPS.CREATE_NEW)"
              >
                {{ $t('task.addEditFieldDialog.controls.addNewFieldBtn') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                x-large
                class="floating-text-btn"
                style="height: 100px;"
                @click="gotoStep(STEPS.CREATE_REFERENCE)"
              >
                {{ $t('task.addEditFieldDialog.controls.addExistingFieldBtn') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mx-n6">
            <v-col>
              <v-btn
                block
                x-large
                class="floating-text-btn"
                @click="createNewNote"
              >
                {{ $t('task.addEditFieldDialog.controls.addNoteFieldBtn') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item :value="STEPS.CREATE_REFERENCE">
        <v-autocomplete
          v-model="contentItem"
          item-value="id"
          :item-text="(item) => `${item.label} [${item.type.label}]`"
          :loading="!contentItems"
          :label="$t('task.addEditFieldDialog.controls.existingField')"
          :hint="$t('general.field.required')"
          persistent-hint
          :disabled="requestableLoading || !contentItems"
          :error-messages="validationErrorMessageFor('id')"
          :error-count="Number.MAX_VALUE"
          :items="contentItems"
          class="mb-5"
        />
        <v-switch
          v-model="internalValue.infobox"
          :label="$t('task.addEditFieldDialog.controls.showAsInfobox')"
          :disabled="requestableLoading"
          :error-messages="validationErrorMessageFor('info_box')"
          :error-count="Number.MAX_VALUE"
          class="mb-1"
          @change="$event ? internalValue.required = false : undefined"
        />
        <v-switch
          v-model="internalValue.required"
          :label="$t('task.addEditFieldDialog.controls.requiredToCompleteTask')"
          :disabled="requestableLoading"
          :error-messages="validationErrorMessageFor('required')"
          :error-count="Number.MAX_VALUE"
          class="mt-1"
          @change="$event ? internalValue.infobox = false : undefined"
        />
        <v-switch
          v-if="selectedContentItemType && selectedContentItemType.calendar_addable"
          v-model="internalValue.options.add_to_calendar"
          :label="$t('task.addEditFieldDialog.controls.addToCalendar')"
          :disabled="requestableLoading"
          :error-messages="validationErrorMessageFor('add_to_calendar')"
          :error-count="Number.MAX_VALUE"
          class="mt-1"
        />
      </v-window-item>
      <v-window-item :value="STEPS.CREATE_NEW">
        <template v-if="internalValue && contentTypesInternal">
          <template v-if="!isNote">
            <v-text-field
              v-model="internalValue.name"
              :label="$t('task.addEditFieldDialog.controls.fieldName')"
              :hint="$t('general.field.required')"
              persistent-hint
              :disabled="requestableLoading"
              :error-messages="validationErrorMessageFor('label')"
              :error-count="Number.MAX_VALUE"
              class="mb-5"
            />
            <v-autocomplete
              v-model="internalValue.type"
              item-value="type"
              item-text="label"
              :label="$t('task.addEditFieldDialog.controls.fieldType')"
              :hint="$t('general.field.required')"
              persistent-hint
              :disabled="requestableLoading || !isAdd"
              :error-messages="validationErrorMessageFor('content_type')"
              :error-count="Number.MAX_VALUE"
              :items="contentTypesInternal"
              class="mb-5"
              @change="onContentTypeChanged"
            />
            <content-type-options-control
              v-model="internalValue.options"
              :content-type="selectedContentType"
              :disabled="requestableLoading"
              :error-messages="validationErrorFor('options')"
              class="mb-5"
            />
            <v-switch
              v-model="internalValue.infobox"
              :label="$t('task.addEditFieldDialog.controls.showAsInfobox')"
              :disabled="requestableLoading"
              :error-messages="validationErrorMessageFor('info_box')"
              :error-count="Number.MAX_VALUE"
              class="mb-1"
              @change="$event ? internalValue.required = false : undefined"
            />
            <v-switch
              v-model="internalValue.required"
              :label="$t('task.addEditFieldDialog.controls.requiredToCompleteTask')"
              :disabled="requestableLoading"
              :error-messages="validationErrorMessageFor('required')"
              :error-count="Number.MAX_VALUE"
              class="mt-1"
              @change="$event ? internalValue.infobox = false : undefined"
            />
            <v-switch
              v-if="selectedContentType && selectedContentType.calendar_addable"
              v-model="internalValue.options.add_to_calendar"
              :label="$t('task.addEditFieldDialog.controls.addToCalendar')"
              :disabled="requestableLoading"
              :error-messages="validationErrorMessageFor('add_to_calendar')"
              :error-count="Number.MAX_VALUE"
              class="mt-1"
            />
          </template>
          <data-control
            v-if="showDataControl"
            ref="dataControl"
            v-model="internalValue.value"
            :type="dataControlType(internalValue.type)"
            :label="isNote ? $t('task.addEditFieldDialog.controls.noteValue') : $t('task.addEditFieldDialog.controls.defaultValue.label')"
            :options="internalValue.options"
            :hint="isNote ? undefined : $t('task.addEditFieldDialog.controls.defaultValue.hint')"
            persistent-hint
            class="mt-2"
          />
        </template>
        <div
          v-else-if="!contentTypes"
          class="fill-height d-flex justify-center align-center"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </div>
      </v-window-item>
    </v-window>
  </custom-dialog>
</template>
<style scoped lang="scss">
  .floating-text-btn :deep(.v-btn__content) {
      white-space: normal;
      width: 100%;
  }
</style>
