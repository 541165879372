<script>
import { PageContentable } from '../page'
import VisibilitySummary from 'components/visibility-summary.vue'
import MentioningHint from 'components/mentioning-hint.vue'
import LabelsControl from 'controls/labels-control.vue'
import Dossier from 'mixins/models/dossier'

export default {
  name: 'DossierEditSidebarRight',
  components: {
    LabelsControl,
    VisibilitySummary,
    MentioningHint
  },
  mixins: [PageContentable, Dossier],
  computed: {
    dossier () {
      return this.value
    }
  }
}
</script>
<template>
  <div v-if="value">
    <labels-control
      v-model="dossier.labels"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.labels.updateAssignment(), mapping: 'labels', params: {object_gid: dossierGlobalId}}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.labels.list()}"
      label="Labels"
      success-message=""
      indent
      sidebar
    />
    <v-divider />

    <visibility-summary :value="value.groups" />
    <v-divider />

    <mentioning-hint
      :identifier="dossierIdentifier"
      path="dossier.mentioningHint"
    />
    <v-divider />
  </div>
</template>
