<script>
import Vue from 'vue'
import UserInfo from './user-info.vue'
import Snackbars from './snackbars.vue'
import Avatar from 'components/avatar.vue'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import { routeFor } from 'helpers/route'
import trim from 'lodash/trim'
import logo from 'assets/images/ms-icon-310x310.png'

export default {
  name: 'SiteHeader',
  components: { UserInfo, Avatar, Snackbars },
  data () {
    return {
      drawer: false,
      query: '',
      logo,
      searchActive: false,
      mainMenuEntries: [],
      menuRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: data => {
            this.mainMenuEntries = data.result.map(entry => this.convertEntry(entry))
          }
        }
      })
    }
  },
  channels: {
    MenuChannel: {
      connected () { this.$debugLog('connected to MenuChannel via vue') },
      rejected () {},
      received (data) {
        if (data?.action === 'menu_updated') this.loadMenuEntries()
      },
      disconnected () {}
    }
  },
  computed: {
    homeRouteActiveClass () {
      return this.$route.name === this.$router.resolve({ name: 'home' }).resolved.name ? 'v-btn--active' : ''
    },
    secondaryMenuElements () {
      return [
        {
          condition: true,
          entries: [
            { type: 'item', to: { name: 'process-definitions' }, title: 'Prozessvorlagen' },
            { type: 'item', to: { name: 'dossier-definitions' }, title: 'Dossiervorlagen' },
            { type: 'item', to: { name: 'labels' }, title: 'Labels' },
            { type: 'item', to: { name: 'users' }, title: 'Nutzer:innen' },
            { type: 'item', to: { name: 'groups' }, title: 'Gruppen' },
            { type: 'item', to: { name: 'automations' }, title: 'Automatisierungen' },
            { type: 'item', to: { name: 'journal' }, title: 'Journal' }
          ]
        }
      ]
    },
    adminMenuElements () {
      return [
        {
          condition: this.$config.current_user.isAdmin,
          entries: [
            { type: 'item', to: { name: 'main-menu-entries' }, title: 'Hauptmenü konfigurieren' },
            { type: 'item', to: { name: 'static-pages' }, title: 'Statische Seiten' },
            { type: 'item', to: { name: 'footer-menu-entries' }, title: 'Fußzeilenmenü konfigurieren' }
          ]
        },
        {
          condition: this.$config.current_user.isSuperAdmin,
          entries: [
            { type: 'item', href: '/admin/', target: '_blank', title: 'Rails Admin' },
            { type: 'item', href: '/good_job/', target: '_blank', title: 'Good Job' }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$config.isAuthenticated()) {
      this.loadMenuEntries()
      this.$cable.subscribe({
        channel: 'MenuChannel'
      })
    }
  },
  methods: {
    ...{ routeFor },
    loadMenuEntries () {
      this.menuRequestable.request(
        { method: Request.GET, url: this.$apiEndpoints.mainMenuEntries.indexFiltered() },
        null, null, true
      )
    },
    routeNameForType (type) {
      switch (type) {
        case 'workflow_definition': return 'tasks_by_definition'
        case 'dossier_definition': return 'dossiers_by_definition'
        default: return null
      }
    },
    convertEntry (entry) {
      return (entry.type === 'submenu'
        ? {
            type: 'submenu', title: entry.title, childEntries: entry.childEntries.map(entry => this.convertEntry(entry))
          }
        : {
            type: 'item', to: this.routeFor(this.routeNameForType(entry.type), entry.linkedId), title: entry.title
          }
      )
    },
    search () {
      this.query = trim(this.query)
      if (this.query !== '') {
        this.$router.push({ name: 'search', params: { query: this.query } })
      }
    },
    activateSearch () {
      this.searchActive = true
      this.$nextTick(() => {
        this.$refs.searchTextField.focus()
      })
    }
  }
}
</script>
<template>
  <div>
    <v-system-bar
      v-if="$config.flags.showExtendedVersionInfo"
      height="20"
      color="yellow"
      app
    >
      <span>
        {{ $config.flags.revisionHuman }}
      </span>
      <v-spacer />
      <span>
        this is from Rails.root.join("REVISION_TEXT")
      </span>
    </v-system-bar>
    <v-app-bar
      app
      dark
      clipped-left
      clipped-right
      color="blue darken-3"
      class="v-app-bar--custom"
    >
      <v-toolbar-items
        class="mr-2 flex-shrink-1"
      >
        <v-btn
          :to="{ name: 'home' }"
          exact
          text
          class="site-header-home-link text-none text-h5"
          :class="homeRouteActiveClass"
        >
          <v-img
            class="mr-lg-2"
            :src="logo"
            :max-height="28"
            :max-width="28"
            contain
          />
          <div class="text-truncate hidden-md-and-down">
            {{ $t('general.appName') }}
          </div>
        </v-btn>
      </v-toolbar-items>

      <v-app-bar-nav-icon
        v-if="$config.current_user"
        @click="drawer = true"
      />

      <template v-if="$config.current_user">
        <v-spacer class="hidden-sm-and-down" />
        <v-slide-group
          v-if="!searchActive"
          show-arrows
          class="v-toolbar__items mr-8 hidden-sm-and-down flex-shrink-1"
          style="min-width: 0;"
        >
          <v-slide-item
            v-for="(entry, indexEntry) in mainMenuEntries"
            :key="`main_menu_entry_${indexEntry}`"
          >
            <v-btn
              v-if="entry.type === 'item'"
              :to="entry.to"
              text
              style="border-radius: 0; height: 100%!important; max-height: none;"
            >
              {{ entry.title }}
            </v-btn>
            <v-menu
              v-else-if="entry.type === 'submenu'"
              bottom
              offset-y
            >
              <template #activator="{ on }">
                <v-btn
                  text
                  style="border-radius: 0; height: 100%!important; max-height: none;"
                  v-on="on"
                >
                  {{ entry.title }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(childEntry, childEntryIndex) in entry.childEntries"
                  :key="`main_menu_entry_${indexEntry}_subentry_${childEntryIndex}`"
                  :to="childEntry.to"
                >
                  <v-list-item-content>{{ childEntry.title }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-slide-item>
        </v-slide-group>

        <div
          class="d-flex mr-sm-8"
          style="flex-grow: 99;"
        >
          <v-slide-x-transition>
            <v-text-field
              v-show="searchActive"
              id="fulltext-search"
              ref="searchTextField"
              v-model="query"
              type="search"
              flat
              solo-inverted
              hide-details
              append-icon="mdi-magnify"
              label="Suchen"
              @keydown.enter="search"
              @blur="searchActive = false"
            />
          </v-slide-x-transition>
        </div>

        <v-btn
          v-if="!searchActive"
          id="activate-search-btn"
          icon
          @click="activateSearch"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <user-info
          v-if="!searchActive"
          class="mr-2 mr-sm-8"
        />

        <v-menu
          left
          bottom
          offset-y
        >
          <template #activator="{ on }">
            <v-btn
              id="user-menu"
              icon
              v-on="on"
            >
              <avatar
                :text="$config.current_user.avatar.label"
                :image="$config.current_user.avatar.url"
                white
              />
            </v-btn>
          </template>
          <v-list id="user-menu-list">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $config.current_user.fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{ $config.current_user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item :to="routeFor('user_settings', $config.current_user.id)">
              <v-list-item-content>Nutzerprofil</v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://www.samarbeid.org/support"
              target="_blank"
            >
              <v-list-item-content>Hilfe</v-list-item-content>
            </v-list-item>
            <v-list-item :to="routeFor('about')">
              <v-list-item-content>Über samarbeid</v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item href="/users/sign-out">
              <v-list-item-content>Abmelden</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <snackbars v-if="$config.current_user" />

    <v-navigation-drawer
      v-if="$config.current_user"
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-subheader
          class="d-flex text-h5 mx-n2 mt-n2 px-4 py-2 primary"
          :style="$vuetify.breakpoint.smAndDown ? 'height: 56px;' : 'height: 64px;'"
        >
          <v-img
            class="mr-2"
            :src="logo"
            :max-height="28"
            :max-width="28"
            contain
          />
          <div class="white--text text-truncate">
            {{ $t('general.appName') }}
          </div>
        </v-subheader>

        <v-list-item
          href="/"
          exact
          :class="homeRouteActiveClass"
        >
          <v-list-item-content>Dashboard</v-list-item-content>
        </v-list-item>
        <v-divider />

        <div
          v-for="(entry, indexEntry) in mainMenuEntries"
          :key="`mobile_main_menu_entry_${indexEntry}`"
        >
          <v-list-item
            v-if="entry.type === 'item'"
            :to="entry.to"
          >
            <v-list-item-content>{{ entry.title }}</v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else-if="entry.type === 'submenu'"
            no-action
          >
            <template #activator>
              <v-list-item-content class="font-weight-medium">
                {{ entry.title }}
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(childEntry, childEntryIndex) in entry.childEntries"
              :key="`mobile_secondary_menu_entry_${indexEntry}_subentry_${childEntryIndex}`"
              :to="childEntry.to"
              class="pl-5"
            >
              <v-list-item-content>{{ childEntry.title }}</v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <v-divider />

        <v-list-item
          exact
          :to="{ name: 'tasks' }"
        >
          <v-list-item-content>Alle Aufgaben</v-list-item-content>
        </v-list-item>
        <v-list-item
          exact
          :to="{ name: 'dossiers' }"
        >
          <v-list-item-content>Alle Dossiers</v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-group no-action>
          <template #activator>
            <v-list-item-content class="font-weight-medium">
              Einstellungen
            </v-list-item-content>
          </template>

          <template v-for="(section, indexSection) in secondaryMenuElements">
            <template v-if="section.condition">
              <template v-for="(entry, indexEntry) in section.entries">
                <v-divider
                  v-if="entry.type === 'divider'"
                  :key="`mobile_secondary_menu_section_${indexSection}_entry_${indexEntry}_divider`"
                  class="ml-3"
                />
                <v-list-item
                  v-else
                  :key="`mobile_secondary_menu_section_${indexSection}_entry_${indexEntry}_item`"
                  :to="entry.to"
                  :href="entry.href"
                  :target="entry.target"
                  class="pl-5"
                >
                  <v-list-item-content>{{ entry.title }}</v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </template>
        </v-list-group>

        <v-list-group
          v-if="$config.current_user.isAdmin"
          no-action
        >
          <template #activator>
            <v-list-item-content class="font-weight-medium">
              Admin
            </v-list-item-content>
          </template>

          <template v-for="(section, indexSection) in adminMenuElements">
            <template v-if="section.condition">
              <template v-for="(entry, indexEntry) in section.entries">
                <v-divider
                  v-if="entry.type === 'divider'"
                  :key="`mobile_secondary_menu_section_${indexSection}_entry_${indexEntry}_divider`"
                  class="ml-3"
                />
                <v-list-item
                  v-else
                  :key="`mobile_secondary_menu_section_${indexSection}_entry_${indexEntry}_item`"
                  :to="entry.to"
                  :href="entry.href"
                  :target="entry.target"
                  class="pl-5"
                >
                  <v-list-item-content>{{ entry.title }}</v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style scoped lang="scss">
.v-app-bar {
  &--custom {
    max-width: 100vw; // fix for pages with overflowing content

    & > :deep(.v-toolbar__content) {
      padding-left: 0;
    }
  }
}
</style>
