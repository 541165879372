import TextControl from 'controls/text-control.vue'
import TextareaControl from 'controls/textarea-control.vue'
import RichtextareaControl from 'controls/richtextarea-control.vue'
import NumericControl from 'controls/numeric-control.vue'
import BoolControl from 'controls/bool-control.vue'
import DateControl from 'controls/date-control.vue'
import TimeControl from 'controls/time-control.vue'
import DatetimeControl from 'controls/datetime-control.vue'
import SelectionControl from 'controls/selection-control.vue'
import DossierControl from 'controls/dossier-control.vue'
import FileControl from 'controls/file-control/index.vue'
import NoteControl from 'controls/note-control.vue'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import isEqualWith from 'lodash/isEqualWith'
import has from 'lodash/has'
import map from 'lodash/map'
import { isBoolean } from 'lodash'
import isString from 'lodash/isString'

export const CONTENT_TYPE_STRING = 'string'
export const CONTENT_TYPE_URL = 'url'
export const CONTENT_TYPE_EMAIL = 'email'
export const CONTENT_TYPE_PHONE_NUMBER = 'phone_number'
export const CONTENT_TYPE_PASSWORD = 'password'
export const CONTENT_TYPE_TEXT = 'text'
export const CONTENT_TYPE_RICHTEXT = 'richtext'
const CONTENT_TYPE_INTEGER = 'integer'
const CONTENT_TYPE_DECIMAL = 'decimal'
const CONTENT_TYPE_BOOLEAN = 'boolean'
const CONTENT_TYPE_DATE = 'date'
const CONTENT_TYPE_TIME = 'time'
const CONTENT_TYPE_DATETIME = 'datetime'
const CONTENT_TYPE_SELECTION = 'selection'
export const CONTENT_TYPE_DOSSIER = 'dossier'
export const CONTENT_TYPE_FILE = 'file'
export const CONTENT_TYPE_NOTE = 'note'

export function componentForContentType (type) {
  switch (type) {
    case CONTENT_TYPE_STRING:
    case CONTENT_TYPE_URL:
    case CONTENT_TYPE_EMAIL:
    case CONTENT_TYPE_PASSWORD:
    case CONTENT_TYPE_PHONE_NUMBER:
      return TextControl
    case CONTENT_TYPE_TEXT:
      return TextareaControl
    case CONTENT_TYPE_RICHTEXT:
      return RichtextareaControl
    case CONTENT_TYPE_INTEGER:
    case CONTENT_TYPE_DECIMAL:
      return NumericControl
    case CONTENT_TYPE_BOOLEAN:
      return BoolControl
    case CONTENT_TYPE_DATE:
      return DateControl
    case CONTENT_TYPE_TIME:
      return TimeControl
    case CONTENT_TYPE_DATETIME:
      return DatetimeControl
    case CONTENT_TYPE_SELECTION:
      return SelectionControl
    case CONTENT_TYPE_DOSSIER:
      return DossierControl
    case CONTENT_TYPE_FILE:
      return FileControl
    case CONTENT_TYPE_NOTE:
      return NoteControl
    default:
      console.error('Unsupported content type:', type)
      return null
  }
}

export function componentOptionsForContentType (type) {
  switch (type) {
    case CONTENT_TYPE_PASSWORD:
      return { type: 'password' }
    case CONTENT_TYPE_URL:
      return { type: 'url' }
    case CONTENT_TYPE_EMAIL:
      return { type: 'email' }
    case CONTENT_TYPE_PHONE_NUMBER:
      return { type: 'phone-number' }
    case CONTENT_TYPE_INTEGER:
      return { type: 'integer' }
    case CONTENT_TYPE_DECIMAL:
      return { type: 'decimal' }
    case CONTENT_TYPE_DATE:
    case CONTENT_TYPE_DATETIME:
      return { add_to_calendar: false }
    default: return {}
  }
}

export function isContentTypeValueEmpty (type, value) {
  if (value === null || value === undefined) return true

  switch (type) {
    case CONTENT_TYPE_STRING:
    case CONTENT_TYPE_URL:
    case CONTENT_TYPE_EMAIL:
    case CONTENT_TYPE_PASSWORD:
    case CONTENT_TYPE_TEXT:
    case CONTENT_TYPE_RICHTEXT:
      return value === ''
    case CONTENT_TYPE_SELECTION:
    case CONTENT_TYPE_DOSSIER:
    case CONTENT_TYPE_FILE:
      return isArray(value) && isEmpty(value)
  }
}

export function updateValueForContentType (type, value) {
  switch (type) {
    case CONTENT_TYPE_FILE:
    case CONTENT_TYPE_DOSSIER:
      if (isArray(value)) {
        return map(value, 'id')
      } else if (isObject(value) && has(value, 'id')) {
        return value.id
      } else {
        return value
      }
    default:
      return value
  }
}

export function contentTypeValuesEqual (type1, value1, type2, value2) {
  const value1IsEmpty = isContentTypeValueEmpty(type1, value1)
  const value2IsEmpty = isContentTypeValueEmpty(type2, value2)

  if (value1IsEmpty !== value2IsEmpty) {
    return false
  } else if (value1IsEmpty && value2IsEmpty) {
    return true
  } else {
    let updateValue1 = updateValueForContentType(type1, value1)
    let updateValue2 = updateValueForContentType(type2, value2)

    switch (type1) {
      case CONTENT_TYPE_FILE:
      case CONTENT_TYPE_DOSSIER:
        if (isArray(updateValue1)) updateValue1 = updateValue1.sort()
    }

    switch (type2) {
      case CONTENT_TYPE_FILE:
      case CONTENT_TYPE_DOSSIER:
        if (isArray(updateValue2)) updateValue2 = updateValue2.sort()
    }

    return JSON.stringify(updateValue1) === JSON.stringify(updateValue2)
  }
}

export function contentTypeOptionsEqual (options1, options2) {
  return isEqualWith(options1, options2, optionCompare)
}

function optionCompare (option1, option2) {
  if ((isArray(option1) && isArray(option2)) || (isObject(option1) && isObject(option2))) {
    return undefined
  }

  if (isBoolean(option1) && option1 === false) option1 = null
  if (isString(option1) && option1 === '') option1 = null

  if (isBoolean(option2) && option2 === false) option2 = null
  if (isString(option2) && option2 === '') option2 = null

  return option1 === option2
}
