<script>
import KebabMenu from 'components/kebab-menu.vue'
import isNil from 'lodash/isNil'

export default {
  name: 'PageListHeader',
  components: {
    KebabMenu
  },
  props: {
    title: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      default: undefined
    },
    primaryActionTitle: {
      type: String,
      default: undefined
    },
    primaryActionIcon: {
      type: String,
      default: 'mdi-plus'
    },
    secondaryActionsIcon: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasCount () {
      return !isNil(this.count)
    }
  }
}
</script>
<template>
  <div
    class="d-flex align-end mt-md-4 page-list-header"
    style="position: relative"
  >
    <v-chip
      v-if="hasCount"
      small
      class="align-self-center mr-2"
      style="margin-bottom: -0.3rem;"
    >
      {{ count }}
    </v-chip>
    <h1 class="text-h4 font-weight-light pt-1 flex-grow-1 text-truncate">
      {{ title }}
    </h1>

    <slot name="primary-action">
      <v-btn
        v-if="primaryActionTitle"
        color="primary"
        :text="!$vuetify.breakpoint.xs"
        :icon="$vuetify.breakpoint.xs"
        v-on="$listeners"
      >
        <v-icon v-if="$vuetify.breakpoint.xs">
          {{ primaryActionIcon }}
        </v-icon>
        <template v-else>
          {{ primaryActionTitle }}
        </template>
      </v-btn>
    </slot>

    <kebab-menu
      color="primary"
      :v-menu-props="{ bottom: true, left: true, 'offset-y': true, 'nudge-bottom': '8'}"
      :icon="secondaryActionsIcon"
    >
      <template #items="{ closeMenu }">
        <slot
          name="secondary-items"
          :close-menu="closeMenu"
        />
      </template>
    </kebab-menu>
  </div>
</template>
