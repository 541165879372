<script>
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import ResetBtn from 'components/reset-btn.vue'
import CopyToClipboardBtn from 'components/copy-to-clipboard-btn.vue'
import Cacheable from 'mixins/cacheable'
import Controlable from 'mixins/controlable'
import { localeString2Number, number2LocaleString } from 'helpers/number'
import defaults from 'lodash/defaults'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import debounce from 'lodash/debounce'

export default {
  name: 'NumericControl',
  components: {
    ResetBtn,
    CopyToClipboardBtn
  },
  mixins: [Requestable, Cacheable, Controlable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: [Number, String],
      default: undefined
    },
    hint: {
      type: String,
      default: 'Bitte Zahl eingeben'
    }
  },
  computed: {
    internalOptions () {
      return defaults({
        decimalLength: isNil(this.options?.decimalLength) ? 2 : this.options.decimalLength
      }, this.options, {
        type: 'integer',
        min: null,
        max: null,
        allowNegative: true,
        prefix: undefined,
        suffix: undefined
      })
    },
    valueAsInteger () {
      return this.internalOptions.type === 'integer'
    },
    decimalLength () {
      return this.valueAsInteger ? 0 : this.internalOptions.decimalLength
    },
    cachedValueChanged () {
      const oldValue = isNil(this.cacheValue) ? null : Number(this.cacheValue)
      const newValue = isNil(this.value) ? null : Number(this.value)

      return !isEqual(newValue, oldValue)
    }
  },
  mounted () {
    const input = this.$refs.vcurrencyfield.$refs.textfield.$refs.input
    input.addEventListener('paste', e => {
      const pasteValueAsNumber = localeString2Number((e.clipboardData || window.clipboardData).getData('text'))
      debounce(() => {
        if ((Number(this.value) === Number(this.cacheValue)) && (Number(this.cacheValue) !== pasteValueAsNumber)) {
          this.controlShowErrorMessage(this.$t('control.errors.insert'))
        }
      }, 300)()
    })
  },
  methods: {
    createCacheValue () {
      Cacheable.methods.createCacheValue.call(this)
      if (isString(this.cacheValue) && this.cacheValue.trim().length === 0) this.cacheValue = null
    },
    onRequestSuccess (data) {
      this.controlShowSuccessMessage()
      this.$emit('input', this.cacheValue)

      if (data) {
        this.$emit('request-success-data', data)
      }
    },
    number2LocaleString (n) {
      return number2LocaleString(n, { useGrouping: false })
    },
    onClearButtonPressed () {
      this.cacheValue = null
      this.controlOnBlur()
      this.controlOnChange()
      this.controlOnUnlock()
    }
  }
}
</script>

<template>
  <v-currency-field
    ref="vcurrencyfield"
    v-model="cacheValue"
    :label="label"
    :loading="controlLoading"
    :disabled="controlDisabled"
    :readonly="controlReadonly"
    :dense="controlDense"
    :hide-details="controlHideDetails"
    :class="controlClass"
    :error-count="Number.MAX_VALUE"
    :error-messages="controlErrorMessages"
    :hint="controlHint"
    :persistent-hint="controlPersistentHint"
    :success-messages="controlSuccessMessage"
    :locale="$i18n.locale"
    :auto-decimal-mode="false"
    :value-as-integer="valueAsInteger"
    :decimal-length="decimalLength"
    :default-value="null"
    :min="internalOptions.min"
    :max="internalOptions.max"
    :allow-negative="internalOptions.allowNegative"
    :prefix="internalOptions.prefix"
    :suffix="internalOptions.suffix"
    :rules="rules"
    :clearable="clearable && !controlDisabled"
    @blur="controlOnBlur(); controlOnChange(); controlOnUnlock();"
    @input="controlOnInput"
    @focus="controlOnFocus(); controlOnLock();"
    @click:clear="onClearButtonPressed"
  >
    <template
      v-if="!hideAppend"
      #append
    >
      <reset-btn
        v-if="cachedValueChanged && controlHasError"
        @click="controlReset"
      />
      <copy-to-clipboard-btn
        v-if="cacheValue"
        :value="{'text/plain': number2LocaleString(cacheValue)}"
      />
    </template>
  </v-currency-field>
</template>
